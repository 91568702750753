import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import { catchError, shareReplay } from 'rxjs';

@Injectable() // { providedIn: "root" })
export class RolesGuard  {
  constructor(private router: Router, public auth: AuthService) {}

  canActivate(snapshot: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.auth.hasProfile()) {
      // logged in ...

      const profile = this.auth.getProfile();
      // check roles to access route
      if (snapshot.data['roles'] && !snapshot.data['roles'].includes(profile.role) ) {
        this.router.navigate(["/"])
        return false;
      }

      return true;
    }


    if (!this.auth.isAuthenticated()) {
      this.auth.login(state.url);
      return false;
    }

    this.auth.loadProfile().subscribe({
       next: () => this.router.navigate([state.url]),
       error: () => this.auth.login(state.url)
    });

    return false;
  }
}
