import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { IWikiPage } from 'src/app/model';

import { MatListModule } from '@angular/material/list';
import { RouterLink } from '@angular/router';

interface MatDialogData {
  pages: IWikiPage[];
}

@Component({
    selector: 'app-view-wiki-popup',
    templateUrl: './view-wiki-popup.component.html',
    styleUrls: ['./view-wiki-popup.component.scss'],
    imports: [
        MatDialogModule,
        MatListModule,
        RouterLink
    ]
})
export class ViewWikiPopupComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: MatDialogData,
    public dialogRef: MatDialogRef<ViewWikiPopupComponent>
  ) {}
}
