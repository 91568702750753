<mat-card>
  <mat-card-header>
    <mat-card-title>{{ date | date: 'dd.MM.yyyy' }}</mat-card-title>
  </mat-card-header>

  <mat-divider></mat-divider>

  @for (shift of shifts; track shift) {
    <mat-card-content >
      <h5>{{ shift.company }} - {{ shift.position }}</h5>
      @if (shift.fact_day_hours != 0 || shift.plan_day_hours != 0) {
        <p>
          дневная смена: {{ shift.fact_day_hours }} из {{ shift.plan_day_hours }} (план)
        </p>
      }
      @if (shift.fact_night_hours != 0 || shift.plan_night_hours != 0) {
        <p>
          ночная смена: {{ shift.fact_night_hours }} из {{ shift.plan_night_hours }} (план)
        </p>
      }
      <mat-divider></mat-divider>
    </mat-card-content >
  }

  <mat-card-actions align="end">
    <button mat-raised-button color="primary" (click)="submit()">OK</button>
  </mat-card-actions>

  <mat-card-footer>
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </mat-card-footer>
</mat-card>

