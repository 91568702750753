import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { IBirthdays } from '../../model';
import { ApiService } from '../../services/api.service';
import { AsyncPipe } from '@angular/common';
import { Observable } from 'rxjs';
import { MatListModule } from '@angular/material/list';
// import { MatDividerModule } from '@angular/material/divider';

@Component({
    selector: 'app-view-birthdays',
    templateUrl: './view-birthdays.component.html',
    styleUrls: ['./view-birthdays.component.scss'],
    standalone: true,
    imports: [AsyncPipe, MatListModule],
})
export class ViewBirthdaysComponent implements OnInit {
  birthdays$: Observable<IBirthdays[]>;

  constructor(private apiService: ApiService, public auth: AuthService) {
    this.birthdays$ = this.apiService.getAllBirthdays();
  }

  ngOnInit(): void {
  }

  convertDate(date: string): string {
    // let monthName = moment(3, 'M').format('MMM'); // returns Mar
    return "";
  }
}
