@if (page$ | async; as page) {
  <app-view-doc [html]="page.html" ></app-view-doc>
} @else {
  <div class="view-loader">
    <div class="spinner">
      <div class="double-bounce1 mat-bg-accent"></div>
      <div class="double-bounce2 mat-bg-primary"></div>
    </div>
  </div>
}

