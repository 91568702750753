import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, NgZone } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { catchError, config, EMPTY } from 'rxjs'
import { AlertDialogComponent } from '../components/alert-dialog/alert-dialog.component';
import { ApiError, MiscService } from '../modules/api';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService extends ErrorHandler {
  constructor(
    private dialog: MatDialog,
    private miscService: MiscService,
    // , private ngZone: NgZone
) {
    super();
    config.onUnhandledError = (x) => this.handleError(x);
  }

  override handleError(err: any): void {
    // if (!(err instanceof HttpErrorResponse)) {
    //     err = err.rejection; // get the error object
    //   }

    let message: string = ''  // default message;
    if (err instanceof HttpErrorResponse) {
      message = err.message
    } else if (err instanceof ApiError) {
      // new path with OpenAPI 3.0 calls
      message = err.message
    } else {
      message = err.toString();
    }

    console.error(err);

    var stackError = new Error();
    const stackTrace = stackError.stack ? stackError.stack : "no stack"

    if (environment.production) {
      this.miscService.logErrorApiServiceLogErrorPost(message, stackTrace).pipe(
        catchError(() => EMPTY)
      ).subscribe();
    }

    this.dialog.open(AlertDialogComponent, {
      data: {  message: message },
    });

    // solution as provided by Vugar Abdullayev to Stack Overflow question
    // https://stackoverflow.com/questions/69138275/
    // https://github.com/angular/components/issues/7550#issuecomment-345250406
    // this.ngZone.run(() => {
    //     console.log("Global hander !!", err)
    //     this.dialog.open(AlertDialogComponent, {
    //       data: { icon: 'Error', message: "aaa", buttonText: 'Uh oh!' },
    //     });        
    // });
  }
}
