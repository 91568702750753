import { Component, OnInit } from '@angular/core';
import { FormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ChartOptions } from 'chart.js';
import dayjs from 'dayjs';
import { ApiService } from '../../services/api.service';
import { MatTableModule } from '@angular/material/table';
import { NgChartsModule } from 'ng2-charts';
import { MatCardModule } from '@angular/material/card';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';


interface ICacheGraph {
  label: string;
  data: number[];
  fill?: boolean,
  tension?: number,
  borderColor?: string,
  pointBackgroundColor?: string
  background?: string;
}

interface ILocalHotelGraphs {
  hotel: string;
  labels: string[];
  graphs: ICacheGraph[];
}


@Component({
    selector: 'app-view-gri',
    templateUrl: './view-gri.component.html',
    styleUrls: ['./view-gri.component.scss'],
    standalone: true,
    imports: [
    MatFormFieldModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    MatOptionModule,
    MatCardModule,
    NgChartsModule,
    MatTableModule
],
})
export class ViewGriComponent implements OnInit {
  periods = this.getPeriods();
  periodControl = new FormControl(this.periods[0], Validators.required);
  displayedColumns = ['category', 'value'];
  colors = ['#820263', '#cc3f0c', '#9cb33c', '#33658a'];

  // https://www.chartjs.org/docs/3.9.1/getting-started/v3-migration.html#_3-x-migration-guide
  lineChartOptions: ChartOptions = {
    aspectRatio: 4 / 3,
    scales: {
      y: {
          ticks: {
            maxTicksLimit: 6,
          },
          min: 0,
          max: 1,
      },
      x: {
          offset: true,
          // position: 'top',
          // ticks: {
          //   callback: (value: number | string, index: number): string => {
          //     // if (index % 5 === 0) {
          //       const date = new Date(value);
          //       return date.getDate().toString();
          //     // }
          //     // return "";
          //   },
          // },
      },
    },
    plugins: {
      tooltip: {
        mode: 'index',
      },
      legend: {
        display: false,
        position: 'chartArea',
      },
      // datalabels: {
      //   display: true,
      //   align: 'top',
      //   anchor: 'end',
      //   color: 'rgb(35, 132, 177)',
      //   // font: {
      //   //   family: 'FontAwesome',
      //   //   size: 14,
      //   // },
      // },
      // deferred: false,
    },
  };

  public chartType = 'line';
  public griGraphs: ILocalHotelGraphs[] | null = null;

  constructor(private apiService: ApiService) {}

  ngOnInit(): void {
    this.loadGraphs();
  }

  loadGraphs(): void {
    this.griGraphs = null;
    this.apiService.getEmployeeGriGraphs(this.periodControl.value as string).subscribe((griGraphs) => {
      this.griGraphs = []
      for (const hotelGraphs of griGraphs) {
        const graphs: ILocalHotelGraphs = {
          hotel: hotelGraphs.hotel,
          labels: hotelGraphs.labels,
          graphs: []
        }
        this.griGraphs.push(graphs);

        for (const [i, serviceGraph] of hotelGraphs.graphs.entries()) {
          const color = this.colors[i % this.colors.length];
          graphs.graphs.push({
            label: serviceGraph.service,
            data: serviceGraph.values.map((v) => Math.round(v * 1000) / 1000),
            fill: false,
            borderColor: color,
            pointBackgroundColor: color,
          });
        }
      }
    });
  }

  public getPeriods(): string[] {
    const now = dayjs();
    return Array.from({ length: 12 }, (_value, index) => index).map((x) => now.subtract(x, 'month').format('YYYY-MM'));
  }

  public mean(arr: number[]): number {
    return arr.reduce((a, b) => a + b, 0) / arr.length;
  }
}
