import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SafeResourceUrl } from '@angular/platform-browser';
import { ApiService } from '../../services/api.service';
import { AuthService } from '../../services/auth.service';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';

import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import { MatTabsModule } from '@angular/material/tabs';
import { FlexModule } from '@angular/flex-layout/flex';

@Component({
    selector: 'app-view-personal-services',
    templateUrl: './view-personal-services.component.html',
    styleUrls: ['./view-personal-services.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
    FlexModule,
    MatTabsModule,
    FormsModule,
    ReactiveFormsModule,
    MatCardModule,
    MatFormFieldModule,
    MatSelectModule,
    MatOptionModule,
    MatButtonModule
],
})
export class ViewPersonalServicesComponent implements OnInit {
  public url: SafeResourceUrl = "";
  public periodOptions = ['2023', '2022', '2021', '2020', '2019', '2018'];
  public workplaceOptions: string[] = [];
  public ndflFormGroup = this.fb.group({
    period: ['', [Validators.required]],
    workplace: ['', [Validators.required]],
  });

  constructor(
    public auth: AuthService,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    public apiService: ApiService
  ) {
    this.workplaceOptions = [];
    if (this.auth.profile && this.auth.profile.personaldata && this.auth.profile.personaldata.employments)
      for (const e of this.auth.profile.personaldata.employments) {
        this.workplaceOptions.push(e.organization);
      }
  }

  get period(): FormControl {
    return <FormControl>this.ndflFormGroup.get('period');
  }

  get workplace(): FormControl {
    return <FormControl>this.ndflFormGroup.get('workplace');
  }

  ngOnInit(): void {
  }

  submitNDFLRequest(): void {
    this.apiService
      .sendNDFLEmail(this.workplace.value, this.period.value)
      .subscribe(() => {
        this.snackBar.open('Запрос отправлен', '', {
          duration: 2000,
        });
        this.ndflFormGroup.patchValue({ period: '', workplace: '' });
      });
  }
}
