import { AfterViewInit, Component, ElementRef, EventEmitter, OnInit, Output, Self, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, Validators, FormsModule, ReactiveFormsModule, AbstractControl, ValidationErrors, ValidatorFn, FormGroup, DefaultValueAccessor } from '@angular/forms';
import { Router, RouterLink } from '@angular/router';
import { AuthService2 } from '../../services/auth.service';
import { IProfile } from 'src/app/model';
import { BehaviorSubject, EMPTY, Observable, Subject, catchError, map, merge, of, shareReplay, switchMap, tap, throwError, timer } from 'rxjs';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { AsyncPipe } from '@angular/common';
import { PhoneMaskDirective } from '../../directives/phone-mask.directive';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FlexModule } from '@angular/flex-layout/flex';
import { AuthLayoutComponent } from '../auth-layout/auth-layout.component';
import { environment } from 'src/environments/environment';
import { ApiService } from 'src/app/services/api.service';
import { AutofillMonitor } from '@angular/cdk/text-field';

// - сборос пароля
// - извещение Васильева о запросе на сброс пароля из отдельного диалога
// - дебажня должность
// - лишние обращения урать к user_status когда телефон невалидный
// - тут оставить заметку по логике и стаусу пользователя
// + проверить назначение должностей (перенести это в TimeTables)
// ! посмотреть как селать первое обновление статуса при открытии диалога и автозаполнеии
//   надо дотронуться до окна, иначе не валидирует автофил (это специально для безопасности, ничего не сделать) !!

export class CustomValidators {
  static MatchValidator(source: string, target: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const sourceCtrl = control.get(source);
      const matchingControl = control.get(target);

      if (!sourceCtrl || !matchingControl) {
        return null;
      }

      if (matchingControl.errors && !matchingControl.errors['mismatch']) {
        return null;
      }
      if (sourceCtrl.value !== matchingControl.value) {
          matchingControl.setErrors({ mismatch: true });
      } else {
          matchingControl.setErrors(null);
      }
      return null;
    };
  }
}

@Component({
    selector: 'app-view-login-simple',
    templateUrl: './view-login-simple.component.html',
    styleUrls: ['./view-login-simple.component.scss'],
    imports: [
        AuthLayoutComponent,
        FormsModule,
        FlexModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        PhoneMaskDirective,
        MatButtonModule,
        MatIconModule,
        RouterLink,
        AsyncPipe
    ]
})
export class ViewLoginSimpleComponent implements OnInit {
  passwordVisibility = false;
  confirmPasswordVisibility = false;
  version = environment.appVersion;

  public loginForm: FormGroup;

  userStatus$: Observable<string>;

  constructor(private fb: FormBuilder, public auth: AuthService2, public apiService: ApiService, public router: Router,
    ) {
    this.loginForm = this.fb.group({
        phoneNumber: ['', [Validators.minLength(13), Validators.required]],
        password: ['', [Validators.minLength(6), Validators.required]],
        confirmPassword: ['', [Validators.minLength(6), Validators.required]],
      },
      { validators: CustomValidators.MatchValidator('password', 'confirmPassword')}
    );

    this.userStatus$ = this.phoneNumber.valueChanges.pipe(
      switchMap((value) => {
        // console.log('checking: ', value);
        const phone = (value || '').toString();
        return this.apiService.check_user(phone);
      }),
      // map(() => 'first_login'),  // debug
      tap((status) => {
        if (status == 'first_login')
          this.confirmPassword.enable()
        else
        this.confirmPassword.disable()
      }),
      shareReplay()
    );
  }

  get phoneNumber(): FormControl {
    return <FormControl>this.loginForm.get('phoneNumber');
  }
  get password(): FormControl {
    return <FormControl>this.loginForm.get('password');
  }
  get confirmPassword(): FormControl {
    return <FormControl>this.loginForm.get('confirmPassword');
  }

  ngOnInit(): void {
    // if (this.auth.isActivated()) {
    //   this.router.navigate(['/']);
    // } else
    if (this.auth.isAuthenticated()) {
      this.router.navigate(['/']);  // rodo: get back activation ['/activate'], now root
    }
  }

  togglePasswordVisibility(): void {
    this.passwordVisibility = !this.passwordVisibility;
  }

  toggleConfirmPasswordVisibility(): void {
    this.confirmPasswordVisibility = !this.confirmPasswordVisibility;
  }

  login(): void {
    this.loginForm.disable();
    this.auth.authenticate(this.phoneNumber?.value, this.password?.value).pipe(
        catchError((e) => {
          this.loginForm.enable();
          if (e.error === 'Error.UserNotRegistered') {
            this.phoneNumber?.setErrors({ wrongCredentials: true });
          } else {
            this.password?.setErrors({ wrongCredentials: true });
          }
          return EMPTY; // stop error propagation
          // return throwError(e);
        })
      )
      .subscribe((p) => {
          this.router.navigate(['/magazine']);
      })
  }
}
