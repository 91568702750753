import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Data, NavigationEnd, PRIMARY_OUTLET, Router, RouterLink, RouterOutlet } from '@angular/router';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { fromEvent, interval, merge, Observable, of, timer } from 'rxjs';
import { catchError, delay, filter, map, mapTo, repeat, retry, shareReplay, switchMap, timeout } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CustomWindow } from './model';
import { AuthService } from './services/auth.service';
import { RoutePartsService } from './services/route-parts.service';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { FlexModule } from '@angular/flex-layout/flex';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { MatDividerModule } from '@angular/material/divider';
import { AccountBoxComponent } from './components/account-box/account-box.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { AsyncPipe, DOCUMENT, NgTemplateOutlet } from '@angular/common';
import { OpenSidebarOnSwipeDirective } from './directives/open-sidebar-on-swipe.directive';
import { ThemeService } from './services/theme.service';
import { OverlayContainer } from '@angular/cdk/overlay';
import { ApiService } from './services/api.service';
import { UpdateService } from './services/update.service';


declare let window: CustomWindow;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [
    NgTemplateOutlet,
    MatProgressBarModule,
    MatSidenavModule,
    AccountBoxComponent,
    MatDividerModule,
    SidebarComponent,
    FlexModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    RouterLink,
    RouterOutlet,
    AsyncPipe,
    OpenSidebarOnSwipeDirective
],
})
export class AppComponent implements OnInit {
  isLoading?: Observable<boolean>;
  currentPageTitle: string = "";
  offline$?: Observable<boolean>;
  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
    map((result) => result.matches),
    shareReplay()
  );
  drawer_opened: boolean = false;
  drawer_mode: string = "";
  drawer_role: string = "";
  parent_url: string | null = null;
  appTitle = 'Личный кабинет сотрудника';
  pageTitle = '';
  version = environment.appVersion;
  currentThemeClassName = "";

  constructor(
    private breakpointObserver: BreakpointObserver,
    public title: Title,
    public apiService: ApiService,
    public loader: LoadingBarService,
    public auth: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private themeService: ThemeService,
    private updateService: UpdateService,
    private overlayContainer: OverlayContainer,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document
  ) {
    if (!this.updateService.swUpdate.isEnabled) {
      console.log('Service worker not enabled 🙁!');
    }
  }

  displayToolbarAndSidebar(): boolean {
    return this.auth.isAuthenticated() /*&& this.auth.isActivated()*/;
  }

  changePageTitle() {
    // this.router.events.pipe().subscribe((routeChange) => {
   this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event) => {
    const routeChange = event as NavigationEnd;
    if (this.drawer_mode === 'push' || this.drawer_mode === 'over') {
      this.drawer_opened = false;
    }

    // console.log('Route changed', routeChange);
    const urlSegments = this.router.parseUrl(routeChange.urlAfterRedirects).root.children[PRIMARY_OUTLET].segments;

    if (urlSegments.length > 1) {
      this.parent_url = urlSegments[urlSegments.length-2].path;
    } else {
      this.parent_url = null;
    }

    // console.log('Parent URL', this.parent_url );

    if (this.activatedRoute.firstChild)
      this.activatedRoute.firstChild.data.subscribe((data: Data) => {
        // console.log('Route changed', data);
        const title = data['name'];
        if (title) {
          this.title.setTitle(title);
          this.currentPageTitle = title;
        }
    });

    // console.log('Route changed', event);
      // routes to yandex metrika
      //   window.ym(61430950, 'hit', (event as NavigationEnd).url);

      //   window['ym'](61430950, 'hit', event.url, {'params': {'__ymu': {  // non officis __ymu, but works
      //     test: this.auth.profile.login,
      //   }}});  // routes to yandex metrika

      //   if (this.auth.hasProfile()) {
      //     // console.log('Metrika', this.auth.profile);
      //     window.ym(61430950, 'userParams', {
      //       UserID: this.auth.profile.login,
      //       UserDetails: {
      //         Login: this.auth.profile.login,
      //         UserName: this.auth.profile.personaldata.individual_name,
      //       },
      //     });
      //   }
      // });
    });
  }

  ngOnInit(): void {
    this.changePageTitle();

    this.isHandset$.subscribe((e) => {
      this.drawer_opened = e === false;
      this.drawer_mode = e ? 'over' : 'side';
      this.drawer_role = e ? 'dialog' : 'navigation';
    });

    const heartbeat$ = this.apiService.heartbeat().pipe(
        map(() => {
          // console.warn('heartbeat OK');
          return true}), // 'Heartbeat OK.
        timeout(2000), // heartbeatTimeout
        catchError(() => {
          console.warn('heartbeat failed');
          return of(false);
        }),
        delay(3000), // heartbeatDelay
        repeat()
      )

    this.offline$ = merge(
      // of(navigator.onLine),
      // fromEvent(window, 'online').pipe(map(() => true)),
      // fromEvent(window, 'offline').pipe(map(() => false)),
      heartbeat$
    ).pipe(map((x) => !x));

    this.themeService.getThemeClassName().subscribe((newThemeClassName) => {
      if (this.currentThemeClassName) {
        this.overlayContainer.getContainerElement().classList.remove(this.currentThemeClassName);
        this.renderer.removeClass(this.document.body, this.currentThemeClassName);
      }
      if (newThemeClassName) {
        this.overlayContainer.getContainerElement().classList.add(newThemeClassName);
        this.renderer.addClass(this.document.body, newThemeClassName);
      }
      this.currentThemeClassName = newThemeClassName;
    })
  }
}
