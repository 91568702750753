import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject, Observable } from 'rxjs';
import { publish, refCount } from 'rxjs/operators';

@Injectable()
export class NotificationService {
  private _notification: BehaviorSubject<string> = new BehaviorSubject("");
  readonly notification$: Observable<string> = this._notification.asObservable().pipe(publish(), refCount());

  constructor(public snackBar: MatSnackBar) {}

  notify(message: string): void {
    console.log('NotificationService', message);
    this._notification.next(message);
    setTimeout(() => this._notification.next(""), 5000);
    this.snackBar.open(message, 'Закрыть', {
      politeness: 'polite', //  'assertive'
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      duration: 10000,
    });
  }
}
