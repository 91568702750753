@if (griGraphs == null) {
  <div class="view-loader">
    <div class="spinner">
      <div class="double-bounce1 mat-bg-accent"></div>
      <div class="double-bounce2 mat-bg-primary"></div>
    </div>
  </div>
}

<mat-form-field class="selector">
  <mat-label>Период</mat-label>
  <mat-select [formControl]="periodControl" (selectionChange)="loadGraphs()">
    @for (p of periods; track p) {
      <mat-option [value]="p">
        {{ p }}
      </mat-option>
    }
  </mat-select>
</mat-form-field>

@if (griGraphs != null && griGraphs.length == 0) {
  <mat-card>
    <mat-card-content>
      <p>Данные по GRI отелей в заданном периоде отсутствуют</p>
    </mat-card-content>
  </mat-card>
}

@if (griGraphs != null) {
  <div class="gri-charts-grid">
    @for (hotelGraphs of griGraphs; track hotelGraphs) {
      <mat-card>
        <mat-card-header>
          <mat-card-title>{{ hotelGraphs.hotel }}</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <canvas  baseChart width="400" height="400"
            [type]="chartType"
            [datasets]="hotelGraphs.graphs"
            [labels]="hotelGraphs.labels"
            [options]="lineChartOptions"
            [legend]="false"
            >
          </canvas>
          <table mat-table [dataSource]="hotelGraphs.graphs">
            <ng-container matColumnDef="category">
              <th mat-header-cell *matHeaderCellDef>Категория</th>
              <td mat-cell *matCellDef="let element; let i = index" [style.color]="colors[i]">
                {{ element.label }}
              </td>
            </ng-container>
            <ng-container matColumnDef="value">
              <th mat-header-cell *matHeaderCellDef>Значение</th>
              <td mat-cell *matCellDef="let element; let i = index" [style.color]="colors[i]">
                {{ element.data[element.data.length - 1] }}
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>
        </mat-card-content>
      </mat-card>
    }
  </div>
}
