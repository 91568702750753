@if (vacations$ | async; as vacations) {
  <mat-list class="br-list">
    @if (!vacations.length) {
      <mat-list-item lines="3">
        <span matListItemTitle>Нет данных</span>
        <span>Информация по графикам отпусков сотрудника отсутствуют в системе 1С ЗУП</span>
      </mat-list-item>
    }
    @for (vac of vacations; track vac) {
      <mat-list-item [class.status-now]="vac.status=='now'" class="odd_even_items">
        <span matListItemTitle>{{ vac.year }}, {{ vac.view }}, {{ vac.days }} дней(ня)</span>
        <span matListItemLine >{{ vac.date_start }} - {{ vac.date_end }}</span>
      </mat-list-item>
    }
  </mat-list>
} @else {
  <div class="view-loader">
    <div class="spinner">
      <div class="double-bounce1 mat-bg-accent"></div>
      <div class="double-bounce2 mat-bg-primary"></div>
    </div>
  </div>
}

