import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { IPeriodsMagazine } from '../../model';
import { DomSanitizer } from '@angular/platform-browser';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { MatOptionModule } from '@angular/material/core';
import { AsyncPipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { Observable, tap } from 'rxjs';

@Component({
    selector: 'app-view-magazine',
    templateUrl: './view-magazine.component.html',
    styleUrls: ['./view-magazine.component.scss'],
    imports: [
        MatFormFieldModule,
        MatSelectModule,
        FormsModule,
        AsyncPipe,
        MatOptionModule,
        PdfViewerModule
    ]
})
export class ViewMagazineComponent {
  magazines$: Observable<IPeriodsMagazine[]>;
  selectedValue: string = "";
  pdfUrl = ""

  constructor(private api: ApiService, private sanitize: DomSanitizer) {
    this.magazines$ = this.api.getArrayFiles().pipe(
      tap((periods: IPeriodsMagazine[]) => {
        this.selectedValue = periods.length ? periods[0].value : "";
        this.updateMagazinePdfUrl();
      })
    );
  }

  updateMagazinePdfUrl(): void {
    this.pdfUrl = this.api.API_URL + "/api/service/static/magazines/" + this.selectedValue + ".pdf";
  }

}
