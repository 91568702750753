import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MarkdownModule } from 'ngx-markdown';

@Component({
    selector: 'app-view-faq',
    templateUrl: './view-faq.component.html',
    styleUrls: ['./view-faq.component.scss'],
    standalone: true,
    imports: [MarkdownModule],
})
export class ViewFaqComponent implements OnInit {
  faq_asset = '';

  constructor(private http: HttpClient, private router: Router, private route: ActivatedRoute) {}

  ngOnInit(): void {
    console.log(this.route.snapshot.data);
    this.faq_asset = this.route.snapshot.data['asset'];
  }
}
