@if (birthdays$ | async; as birthdays) {
  <mat-list class="br-list">
    @if (!birthdays.length) {
      <mat-list-item lines="3">
        <span matListItemTitle>Нет данных</span>
        <span>Информация о днях рождения сотрудников отсутствует</span>
      </mat-list-item>
    }
    @for (br of birthdays; track br) {
      <mat-list-item class="odd_even_items due-date-{{br.status}}">
        <span matListItemTitle>{{ br.date }}, {{ br.full_name }}</span>
        <span matListItemLine >{{ br.hotel }}, {{ br.position }}</span>
      </mat-list-item>
    }
  </mat-list>
} @else {
  <div class="view-loader">
    <div class="spinner">
      <div class="double-bounce1 mat-bg-accent"></div>
      <div class="double-bounce2 mat-bg-primary"></div>
    </div>
  </div>
}

