import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { Observable, filter } from 'rxjs';

@Injectable()
export class UpdateService {
  constructor(public swUpdate: SwUpdate, private snackbar: MatSnackBar) {
    const updatesAvailable$ = this.swUpdate.versionUpdates.pipe(
      filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY')
      ) as Observable<VersionReadyEvent>;
      updatesAvailable$ .subscribe(evt => {
        console.log('Доступна новая версия: ' + evt.latestVersion.hash);
        const snack = this.snackbar.open('Доступна новая версия', 'Обновить', {duration: 10000});
        snack.onAction().subscribe(() => {
          window.location.reload();
        });
      });
  }
}
