import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { PdfViewerModule } from 'ng2-pdf-viewer';

@Component({
    selector: 'app-view-motivation',
    templateUrl: './view-motivation.component.html',
    styleUrls: ['./view-motivation.component.scss'],
    standalone: true,
    imports: [PdfViewerModule],
})
export class ViewMotivationComponent implements OnInit {
  pdfUrl = ""

  constructor(private api: ApiService) {}

  ngOnInit(): void {
    this.getPdf();
  }

  getPdf(): void {
    this.pdfUrl = this.api.API_URL + "/api/service/static/motivations/motivation.pdf";
  }
}
