@if (!dataSource) {
  <div class="view-loader">
    <div class="spinner">
      <div class="double-bounce1 mat-bg-accent"></div>
      <div class="double-bounce2 mat-bg-primary"></div>
    </div>
  </div>
}

<!-- (click)="value=''" -->
<form [formGroup]="searchForm" (submit)="searchWikiPage()">
  <mat-form-field class="search-field">
    <mat-label>Поиск стандарта</mat-label>
    <input  matInput type="text" formControlName="query">
    @if (noDocumentsFound) {
      <mat-hint><strong>Документы не найдены</strong></mat-hint>
    }
    @if (query.value && !searchInProgress) {
      <button matSuffix mat-icon-button aria-label="Find" type="submit">
        <mat-icon>search</mat-icon>
      </button>
    }
  </mat-form-field>
  @if (searchInProgress) {
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  }
</form>

@if (dataSource && dataSource.data.length == 0) {
  <mat-card>
    <mat-card-content>
      <p>Доступные документы отсутствуют</p>
    </mat-card-content>
  </mat-card>
}

@if (dataSource) {
  <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="wiki-tree">
    <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
      <li class="mat-tree-node">
        <button mat-icon-button disabled></button>
        @if (node.id && node.id != 'None') {
          <a routerLink="{{ node.id }}">
            {{ node.name }}
          </a>
        } @else {
          {{ node.name }}
        }
      </li>
    </mat-tree-node>
    <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
      <li>
        <div class="mat-tree-node">
          <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'toggle ' + node.name">
            <mat-icon class="mat-icon-rtl-mirror">
              {{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
            </mat-icon>
          </button>
          @if (node.id && node.id != 'None') {
            <a routerLink="{{ node.id }}">
              {{ node.name }}
            </a>
          } @else {
            {{ node.name }}
          }
        </div>
        <ul [class.wiki-tree-invisible]="!treeControl.isExpanded(node)">
          <ng-container matTreeNodeOutlet></ng-container>
        </ul>
      </li>
    </mat-nested-tree-node>
  </mat-tree>
}
