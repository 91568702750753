import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService2 } from './auth.service';
import { catchError, shareReplay } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { AlertDialogComponent } from '../components/alert-dialog/alert-dialog.component';

@Injectable() // { providedIn: "root" })
export class RolesGuard  {
  constructor(private router: Router, public auth: AuthService2,
    private dialog: MatDialog
  ) {}

  canActivate(snapshot: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.auth.hasProfile()) {
      // logged in ...
      const profile = this.auth.getProfile();
      if (!profile) 
        return false;      

      if (profile.role === '') {
        this.router.navigate(["/error"]);
        return false;
      }

      // check roles to access route
      if (snapshot.data['roles'] && snapshot.data['roles'].length && !snapshot.data['roles'].includes(profile.role) ) {
        this.router.navigate(["/"]);
        return false;
      }

      return true;
    }

    if (!this.auth.isAuthenticated()) {
      this.auth.login(state.url);
      return false;
    }

    this.auth.loadProfile().subscribe({
       next: () => this.router.navigate([state.url]),
       error: () => this.auth.login(state.url)
    });

    return false;
  }
}
