
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IWikiPage } from '../../model';
import { ApiService } from '../../services/api.service';
import { ViewDocComponent } from '../view-doc/view-doc.component';

@Component({
    selector: 'app-view-static',
    templateUrl: './view-static.component.html',
    styleUrls: ['./view-static.component.scss'],
    standalone: true,
    imports: [
    ViewDocComponent
],
})
export class ViewStaticComponent implements OnInit {
  public page: IWikiPage | null = null;

  constructor(private route: ActivatedRoute, private apiService: ApiService) {}

  ngOnInit(): void {
    this.page = null;
    this.route.params.subscribe((params) => {
      // console.log('ViewStaticComponent', params);
      this.loadWikiPage(params['_id']);
    });
  }

  loadWikiPage(_id: string): void {
    this.apiService.getWikiPage(_id).subscribe((page) => {
      this.page = page;
    });
  }
}
