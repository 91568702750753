<mat-card>
  <mat-card-header>
    <mat-card-subtitle>{{ data.title }}</mat-card-subtitle>
    <mat-card-title>{{ data.salaryPeriod.workplace }}, {{ data.salaryPeriod.period }}</mat-card-title>
  </mat-card-header>

  <mat-divider></mat-divider>

  @for (group of paymentGroups; track group) {
    <mat-card-content>
      <h4>{{ group.name }}</h4>
      @for (payment of group.payments; track payment) {
        <p>
          {{ payment.amount | number: '0.2-2' }}
          <span>- {{ payment.type }}</span>
        </p>
      }
      <mat-divider></mat-divider>
    </mat-card-content>
  }

  <mat-card-actions align="end">
    <button mat-raised-button color="primary" (click)="submit()">OK</button>
  </mat-card-actions>

  <mat-card-footer>
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </mat-card-footer>

</mat-card>
