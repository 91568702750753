@if (loader.value$ | async) {
  <div class="fixed-on-top">
    <mat-progress-bar color="accent" class="loading-bar" mode="determinate" [value]="loader.value$ | async">
    </mat-progress-bar>
  </div>
}

@if (displayToolbarAndSidebar()) {
  <mat-sidenav-container class="sidenav-container">
    <!-- fixedInViewport -->
    <mat-sidenav
      #drawer
      class="sidenav"
      appOpenSidebarOnSwipe [sideNav] = "sidenav" #sidenav
      [attr.role]="drawer_role"
      [(mode)]="drawer_mode"
      [(opened)]="drawer_opened"
      >
      <div class="menu-block">
        <app-account-box></app-account-box>
        <mat-divider></mat-divider>
        <app-sidebar class="menu-scrolled-section"></app-sidebar>
      </div>
    </mat-sidenav>
    <mat-sidenav-content fxLayout="column">
      <mat-toolbar color="primary">
        <mat-toolbar-row>
          @if (!parent_url) {
            <button type="button" mat-icon-button (click)="drawer.toggle()">
              <mat-icon>menu</mat-icon>
            </button>
          }
          @if (parent_url) {
            <button type="button" mat-icon-button routerLink="/{{parent_url}}">
              <mat-icon aria-label="Back icon">arrow_back</mat-icon>
            </button>
          }
          <!-- TODO: Replace by breadcrumbs. -->
          <span>{{ currentPageTitle }}</span>
          <span fxFlex></span>
          <p class="version-tag">{{ version }}</p>
        </mat-toolbar-row>
      </mat-toolbar>
      <ng-container *ngTemplateOutlet="checkConnection"></ng-container>
      <div class="router-wrapper">
        <router-outlet></router-outlet>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
} @else {
  <ng-container *ngTemplateOutlet="checkConnection"></ng-container>
  <div class="router-wrapper">
    <router-outlet></router-outlet>
  </div>
}



<ng-template #checkConnection>
  @if (offline$ | async) {
    <div class="no-connection-bar">
      <p>Нет интернет соединения</p>
      <p>или недоступен сервер данных</p>
    </div>
  }
</ng-template>
