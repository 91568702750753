@if (claims_to_lk$ | async; as claims_to_lk) {
  <div class="container">
    <mat-card class="statistics">
      <!-- <mat-card-header>
      <mat-card-title>Заявки</mat-card-title>
    </mat-card-header> -->
    <mat-card-content>
      <div>Общее количество заявок: {{ claims_to_lk.length }}</div>
      <div>Заявки с начала года: {{ count_claims_this_year }}</div>
    </mat-card-content>
    <mat-card-actions align="end">
      <!-- (click)="openDialog()"> -->
      <button mat-raised-button color="primary" routerLink="./request">
        <!-- <mat-icon>note_add_outlined</mat-icon> -->
        Оставить Заявку
      </button>
    </mat-card-actions>
  </mat-card>
  <mat-list class="list">
    @if (!claims_to_lk.length) {
      <mat-list-item lines="3">
        <span matListItemTitle>Нет данных</span>
        <span>Информация о заявках в сервсный коммитет отсутствует</span>
      </mat-list-item>
    }
    @for (cl of claims_to_lk; track cl) {
      <mat-list-item class="odd_even_items">
        @if (cl.result=='Реализовано') {
          <mat-icon matListItemIcon>check_circle_outline</mat-icon>
        }
        @if (cl.result=='В работе') {
          <mat-icon matListItemIcon>rotate_right</mat-icon>
        }
        @if (cl.result=='Ожидает рассмотрения') {
          <mat-icon matListItemIcon>hourglass_bottom</mat-icon>
        }
        @if (cl.result=='Дублирует стандарты') {
          <mat-icon matListItemIcon>content_copy</mat-icon>
        }
        @if (cl.result=='Отклонено') {
          <mat-icon matListItemIcon>block</mat-icon>
        }
        <span matListItemTitle>№: {{ cl.id }}, {{ cl.fullname }}</span>
        <span matListItemLine >{{ cl.description }}</span>
        <span matListItemLine >Статус: {{ cl.result }}</span>
      </mat-list-item>
    }
  </mat-list>
</div>
} @else {
  <div class="view-loader">
    <div class="spinner">
      <div class="double-bounce1 mat-bg-accent"></div>
      <div class="double-bounce2 mat-bg-primary"></div>
    </div>
  </div>
}

