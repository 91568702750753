<div class="full-height full-width" fxLayout="column" fxLayoutAlign="start stretch">
  <!-- [dynamicHeight]="true" -->
  <mat-tab-group fxFlex id="tab-group">
    <mat-tab label="Справка 2-НДФЛ">
      <form [formGroup]="ndflFormGroup" (ngSubmit)="submitNDFLRequest()">
        <mat-card appearance="outlined">
          <p>Закажите справку 2-НДФЛ</p>
          <mat-form-field class="selector">
            <mat-label>{{ 'Выбор периода' }}</mat-label>
            <mat-select formControlName="period" name="period">
              @for (option of periodOptions; track option) {
                <mat-option [value]="option" ngDefaultControl>
                  {{ option }}
                </mat-option>
              }
            </mat-select>
          </mat-form-field>

          <mat-form-field class="selector">
            <mat-label>{{ 'Выбор организации' }}</mat-label>
            <mat-select formControlName="workplace">
              @for (option of workplaceOptions; track option) {
                <mat-option [value]="option" ngDefaultControl>
                  {{ option }}
                </mat-option>
              }
            </mat-select>
          </mat-form-field>

          <button
            class="m-1 centered"
            mat-raised-button
            color="primary"
            type="submit"
            [disabled]="!ndflFormGroup.valid"
            >
            {{ 'Заказать' }}
          </button>
        </mat-card>
      </form>
    </mat-tab>

    <!-- <mat-tab label="Связь с HR">
  </mat-tab> -->

  <!-- <mat-tab label="Мои настройки">
</mat-tab> -->
</mat-tab-group>
</div>
