/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import type { Birthday } from '../models/Birthday';
import type { Claim } from '../models/Claim';
import type { HotelGraphs } from '../models/HotelGraphs';
import type { HotelShifts } from '../models/HotelShifts';
import type { Vacation } from '../models/Vacation';
import type { WorkplacePositionSalary } from '../models/WorkplacePositionSalary';
import type { WorkShifts } from '../models/WorkShifts';
import { BaseHttpRequest } from '../core/BaseHttpRequest';
@Injectable({
    providedIn: 'root',
})
export class EmployeeService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get Birthdays
     * @returns Birthday Successful Response
     * @throws ApiError
     */
    public getBirthdaysApiEmployeeBirthdaysGet(): Observable<Array<Birthday>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/employee/birthdays',
        });
    }
    /**
     * Get Vacation Schedule
     * @returns Vacation Successful Response
     * @throws ApiError
     */
    public getVacationScheduleApiEmployeeVacationScheduleGet(): Observable<Array<Vacation>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/employee/vacation_schedule',
        });
    }
    /**
     * Get Gri
     * @param period
     * @returns HotelGraphs Successful Response
     * @throws ApiError
     */
    public getGriApiEmployeeGriGet(
        period?: (string | null),
    ): Observable<Array<HotelGraphs>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/employee/gri',
            query: {
                'period': period,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Work Shifts
     * @param period
     * @returns WorkShifts Successful Response
     * @throws ApiError
     */
    public workShiftsApiEmployeeWorkshiftsGet(
        period?: (string | null),
    ): Observable<Array<WorkShifts>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/employee/workshifts',
            query: {
                'period': period,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Hotel Shifts
     * @returns HotelShifts Successful Response
     * @throws ApiError
     */
    public getHotelShiftsApiEmployeeHotelShiftsGet(): Observable<HotelShifts> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/employee/hotel_shifts',
        });
    }
    /**
     * Get Salary
     * @returns WorkplacePositionSalary Successful Response
     * @throws ApiError
     */
    public getSalaryApiEmployeeSalary3Get(): Observable<Array<WorkplacePositionSalary>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/employee/salary3',
        });
    }
    /**
     * Get Salary
     * @returns WorkplacePositionSalary Successful Response
     * @throws ApiError
     */
    public getSalaryApiEmployeeSalaryGet(): Observable<Array<WorkplacePositionSalary>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/employee/salary',
        });
    }
    /**
     * Get Bounty Notebook
     * @param position
     * @param period
     * @param workplace
     * @returns any Successful Response
     * @throws ApiError
     */
    public getBountyNotebookApiEmployeeBountyNotebookGet(
        position: string,
        period: string,
        workplace: string,
    ): Observable<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/employee/bounty_notebook',
            query: {
                'position': position,
                'period': period,
                'workplace': workplace,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
    /**
     * Get Claims
     * @returns Claim Successful Response
     * @throws ApiError
     */
    public getClaimsApiEmployeeClaimServiceGet(): Observable<Array<Claim>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/employee/claim_service',
        });
    }
}
