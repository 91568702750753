import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';

@Injectable() // { providedIn: "root" })
export class AuthGuard  {
  constructor(public auth: AuthService) {}

  canActivate(_: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (environment.production === false) {
      return true;
    }
    if (this.auth.isAuthenticated()) {
      // logged in so return true
      return true;
    }
    if (this.auth.login(state.url)) {
      return true; // immediate login ?
    }
    return false;
  }
}
