@if (worksheets$ | async; as worksheets) {
  <div class="cal-container">
    @if (!worksheets.length) {
      <mat-card>
        <mat-card-header>
          <mat-card-title>Нет данных</mat-card-title>
          <mat-card-subtitle>Информация по графикам сменной работы для сотрудника отсутвуют</mat-card-subtitle>
        </mat-card-header>
      </mat-card>
    }
    @if (worksheets.length != 0) {
      <mat-calendar
        #calendar
        [dateClass]="dateClass"
      ></mat-calendar>
    }
  </div>
} @else {
  <div class="view-loader">
    <div class="spinner">
      <div class="double-bounce1 mat-bg-accent"></div>
      <div class="double-bounce2 mat-bg-primary"></div>
    </div>
  </div>
}

