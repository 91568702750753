import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// import { ViewActivateComponent } from './components/view-activate/view-activate.component';
import { ViewBountyDetailsComponent } from './components/view-bounty-details/view-bounty-details.component';
// import { ViewDebugComponent } from './components/view-debug/view-debug.component';
import { ViewFaqComponent } from './components/view-faq/view-faq.component';
import { ViewGriComponent } from './components/view-gri/view-gri.component';
// import { ViewLoginComponent } from './components/view-login/view-login.component';
import { ViewMagazineComponent } from './components/view-magazine/view-magazine.component';
import { ViewMotivationComponent } from './components/view-motivation/view-motivation.component';
import { ViewPersonalServicesComponent } from './components/view-personal-services/view-personal-services.component';
// import { ViewPreRegisterComponent } from './components/view-pre-register/view-pre-register.component';
// import { ViewRegisterComponent } from './components/view-register/view-register.component';
import { ViewResetPasswordComponent } from './components/view-reset-password/view-reset-password.component';
import { ViewSalaryComponent } from './components/view-salary/view-salary.component';
import { ViewServiceCommiteeComponent } from './components/view-service-commitee/view-service-commitee.component';
import { ViewSpecialOffersComponent } from './components/view-special-offers/view-special-offers.component';
import { ViewStaticComponent } from './components/view-static/view-static.component';
// import { ViewUserManagerComponent } from './components/view-user-manager/view-user-manager.component';
import { ViewWikiComponent } from './components/view-wiki/view-wiki.component';
import { ViewWorkScheduleComponent } from './components/view-work-schedule/view-work-schedule.component';
import { ViewGraphicVacationComponent } from './components/view-graphic-vacation/view-graphic-vacation.component';
import { AuthGuard } from './services/auth.guard';
import { RolesGuard } from './services/roles.guard';
import { ViewBirthdaysComponent } from './components/view-birthdays/view-birthdays.component';
import { ViewServiceCommiteeRequestComponent } from './components/view-service-commitee-request/view-service-commitee-request.component';
import { ViewAccountComponent } from './components/view-account.component/view-account.component';
import { ViewLoginSimpleComponent } from './components/view-login-simple/view-login-simple.component';
import { ViewShiftsComponent } from './components/view-shifts/view-shifts.component';

const routes: Routes = [
  // TODO: Make nested routes.
  // { path: '', redirectTo: 'dashboard', pathMatch: 'full'},
  // { path: 'login', component: ViewLoginComponent, data: { name: 'Вход' } },
  { path: 'login', component: ViewLoginSimpleComponent, data: { name: 'Вход' } },
  // { path: 'pre-register', component: ViewPreRegisterComponent, data: { name: 'Заявка на подключение' } },
  // {
  //   path: 'register',
  //   component: ViewRegisterComponent,
  //   data: { name: 'Регистрация' },
  // },
  {
    path: 'reset_password',
    component: ViewResetPasswordComponent,
    data: { name: 'Сброс пароля' },
  },
  // {
  //   path: 'activate',
  //   component: ViewActivateComponent,
  //   canActivate: [AuthGuard],
  //   data: { name: 'Активация' },
  // },
  {
    path: 'account',
    component: ViewAccountComponent,
    canActivate: [RolesGuard],
    data: { name: 'Профиль' },
  },
  {
    path: 'magazine',
    component: ViewMagazineComponent,
    canActivate: [RolesGuard],
    data: { name: 'Журнал' },
  },
  {
    path: 'wiki',
    component: ViewWikiComponent,
    canActivate: [RolesGuard],
    data: { name: 'Стандарты' },
  },
  {
    path: 'wiki/:_id',
    component: ViewStaticComponent,
    canActivate: [RolesGuard],
    data: { name: 'Стандарт' },
  },
  {
    path: 'gri',
    component: ViewGriComponent,
    canActivate: [RolesGuard],
    data: { name: 'GRI' },
  },
  {
    path: 'motivation',
    component: ViewMotivationComponent,
    canActivate: [RolesGuard],
    data: { name: 'Схема мотивации' },
  },
  {
    path: 'salary',
    component: ViewSalaryComponent,
    canActivate: [RolesGuard],
    data: { name: 'Доходы' },
  },
  // TODO: Change to params for consistency?
  {
    path: 'bounty/:period/:company/:position',
    component: ViewBountyDetailsComponent,
    canActivate: [RolesGuard],
    data: { name: 'Детализация премии', root: 'salary' },
  },
  {
    path: 'work-schedule',
    component: ViewWorkScheduleComponent,
    canActivate: [RolesGuard],
    data: { name: 'График работы' },
  },
  {
    path: 'shifts',
    component: ViewShiftsComponent,
    canActivate: [RolesGuard],
    data: { name: 'Графики смен'},
  },
  {
    path: 'graphic-vacation',
    component: ViewGraphicVacationComponent,
    canActivate: [RolesGuard],
    data: { name: 'График отпусков' },
  },
  {
    path: 'birthday',
    component: ViewBirthdaysComponent,
    canActivate: [RolesGuard],
    data: { name: 'Дни рождения' },
  },
  {
    path: 'personal-services',
    component: ViewPersonalServicesComponent,
    canActivate: [RolesGuard],
    data: { name: 'Личный раздел' },
  },
  {
    path: 'service-commitee',
    component: ViewServiceCommiteeComponent,
    canActivate: [RolesGuard],
    data: { name: 'Сервисный комитет' },
  },
  {
    path: 'service-commitee/request',
    component: ViewServiceCommiteeRequestComponent,
    canActivate: [RolesGuard],
    data: { name: 'Новая заявка' },
  },
  {
    path: 'special-offers',
    component: ViewSpecialOffersComponent,
    canActivate: [RolesGuard],
    data: { name: 'Спецпредложения' },
  },
  {
    path: 'faq',
    component: ViewFaqComponent,
    canActivate: [RolesGuard],
    data: { asset: './assets/faq.md', name: 'FAQ' },
  },
  // TODO: Need protection. For now it can be viewed by anyone.
  {
    path: 'debug',
    // component: ViewDebugComponent,
    loadComponent: () => import('./components/view-debug/view-debug.component').then(m => m.ViewDebugComponent),
    canActivate: [RolesGuard],
    data: { name: 'Admin tools', roles: ['admin'] },
  },
  // {
  //   path: 'set-position',
  //   // component: ViewUserManagerComponent,
  //   loadComponent: () => import('./components/view-user-manager/view-user-manager.component').then(m => m.ViewUserManagerComponent),
  //   canActivate: [RolesGuard],
  //   data: { name: 'Назначить должность', roles: ['admin'] },
  // },
  { path: '**', redirectTo: '/magazine' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
