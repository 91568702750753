import { Component, ViewEncapsulation } from '@angular/core';
import { IMenuItem } from 'src/app/model';
import { AuthService } from '../../services/auth.service';
import { MatIconModule } from '@angular/material/icon';
import { RouterLinkActive, RouterLink } from '@angular/router';

import { MatListModule } from '@angular/material/list';

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
    MatListModule,
    RouterLinkActive,
    RouterLink,
    MatIconModule
],
})
export class SidebarComponent {
  menuItems: IMenuItem[] = [
    { url: '/magazine', name: 'Новости', icon: 'receipt' },
    { url: '/wiki', name: 'Стандарты', icon: 'list' },
    { url: '/gri', name: 'GRI', icon: 'assessment' },
    { url: '/motivation', name: 'Схема мотивации', icon: 'emoji_emotions' },
    { url: '/salary', name: 'Доходы', icon: 'monetization_on' },
    { url: '/work-schedule', name: 'Графики работы', icon: 'calendar_today' },
    { url: '/shifts', name: 'Графики смен', icon: 'calendar_today' },
    { url: '/personal-services', name: 'Личный раздел', icon: 'assignment' },
    { url: '/graphic-vacation', name: 'График отпусков', icon: 'local_airport' },
    { url: '/birthday', name: 'Дни рождения', icon: 'cake' },
    { url: '/special-offers', name: 'Спецпредложения', icon: 'local_offer' },
    { url: '/service-commitee', name: 'Сервисный комитет', icon: 'feedback' },
    { url: '/faq', name: 'FAQ', icon: 'info' },
    { url: '/debug', name: 'Admin tools', icon: 'settings', roles: ['admin'] },
    // { url: '/set-position', name: 'Назначение должности', icon: 'work', roles: ['admin'] },
  ];

  constructor(private authService: AuthService) {}

  get items(): IMenuItem[] {
    var userRole = "";
    if (this.authService.getProfile()) {
      userRole = this.authService.getProfile().role;
    }
    const checkRoles = (menuItem: IMenuItem) => !menuItem.roles || menuItem.roles.includes(userRole);
    return this.menuItems.filter(checkRoles);
  }
}
