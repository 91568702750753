import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { environment } from '../../environments/environment';
import { AuthService2 } from './auth.service';
import { ApiService } from './api.service';

// todo: implement refresh here
// https://www.intertech.com/Blog/angular-4-tutorial-handling-refresh-token-with-new-httpinterceptor/ or
// https://itnext.io/angular-tutorial-implement-refresh-token-with-httpinterceptor-bfa27b966f57
@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private auth: AuthService2, private apiService: ApiService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // add authorization header with jwt token if available
    const res = request.url.indexOf(this.apiService.API_URL);
    if (res !== 0 || request.url.indexOf('heartbeat') > 0 || request.url.indexOf('hotel_shifts') > 0) {
      return next.handle(request); // do nothing
    }

    // console.log('HTTP API request: ', request.method + ' ' + request.url);

    const accessToken = this.auth.getAccessToken();
    if (accessToken) {
      // console.log('JwtInterceptor', accessToken);
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${accessToken}`,
          'Cache-control': 'no-cache',
        },
      });
    }
    const startTime: Date = new Date();
    return next.handle(request).pipe(
      tap( {
        next: () => {
          console.log('HTTP API request:', request.method + ' ' + request.url,
            'time:', (new Date().valueOf() - startTime.valueOf()) / 1000);
        },
        error: () => {
          console.warn('HTTP API request with error:', request.method + ' ' + request.url,
            'time:', (new Date().valueOf() - startTime.valueOf()) / 1000);
        },
      }),
    );
  }
}
