@if (dataSource) {
    <div class="header-container">
        <div>
            <p>График ближайших дежурств (часы)</p>
            <p>Отель <b>M19</b></p>
        </div>
        <mat-form-field>
            <mat-label>Выбор службы</mat-label>
            <mat-select>
                <mat-option value="all">Все</mat-option>
                <mat-option value="spir">СПиР</mat-option>
                <mat-option value="saab">Хозяйственная служба</mat-option>
                <mat-option value="mercedes">Служба питания</mat-option>
                <mat-option value="audi">Служба эксплуатации</mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div class="table-container">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
            <ng-container matColumnDef="name" sticky>
                <th mat-header-cell *matHeaderCellDef>
                    ФИО
                </th>
                <td mat-cell *matCellDef="let element" class="name-font row-height"> {{element.name}} </td>
            </ng-container>
            <ng-container matColumnDef="service" sticky>
                <th mat-header-cell *matHeaderCellDef class="right-border-bold">
                    <!-- <mat-icon>home</mat-icon> -->
                </th>
                <td mat-cell *matCellDef="let element" class="right-border-bold row-height">
                    <mat-icon>{{ get_icon(element.service) }}</mat-icon>
                </td>
            </ng-container>

            @for (disCol of displayedDateCols; track disCol; let colIndex = $index) {
                <ng-container matColumnDef="{{disCol}}">
                    <th mat-header-cell *matHeaderCellDef class="shift-column right-border">
                        {{disCol}}
                    </th>
                    <td mat-cell *matCellDef="let element" class="shift-column right-border row-height cell-font"> 
                        {{ get_shift_text(element, colIndex) }}
                        </td>
                </ng-container>
            }

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="gray row-height"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns" class="row-height"></tr>
        </table>
    </div>

} @else {
    <div class="view-loader">
        <div class="spinner">
            <div class="double-bounce1 mat-bg-accent"></div>
            <div class="double-bounce2 mat-bg-primary"></div>
        </div>
    </div>
}