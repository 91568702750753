@if (periods$ | async; as periods) {
  <mat-list fxLayout="column" fxLayoutAlign="start stretch">
    @if (periods && periods.length == 0) {
      <p>
        Данные о начислениях и трудоустройстве для
        <strong>{{ auth.getProfile()?.personaldata?.individual_name }}</strong>
        отсутствуют
      </p>
    }
    <mat-list-item class="header-block" fxLayout="row">
      <div fxFlex="20" fxFlex.lt-sm="40">Период</div>
      <div fxFlex="20" fxFlex.lt-sm="60">Выплаты</div>
      <div fxFlex fxHide.lt-sm>Примечания</div>
      <!-- <mat-divider></mat-divider> -->
    </mat-list-item>
    <div fxFlex>
      <mat-accordion>
        @for (period of periods; track period) {
          <mat-expansion-panel
            (opened)="period.panelOpened = true"
            (closed)="period.panelOpened = false"
            >
            <mat-expansion-panel-header>
              <mat-list-item fxLayout="row">
                <!-- *ngIf="!period.panelOpened" -->
                <div class="row" fxFlex="20" fxFlex.lt-sm="40">
                  {{ period.name }}
                </div>
                <div class="row" fxFlex="20" fxFlex.lt-sm="60">
                  {{ period.total_onhand | number: '0.2-2' }}
                </div>
                <div class="row" fxFlex fxHide.lt-sm>{{ period.comments }}</div>
              </mat-list-item>
            </mat-expansion-panel-header>
            @for (wp of period.workplacePositions; track wp) {
              <mat-card appearance="outlined" class="salary-card">
                <mat-card-header class="">
                  <mat-card-title>{{ wp.workplace }}</mat-card-title>
                  <mat-card-subtitle>{{ wp.position }}</mat-card-subtitle>
                </mat-card-header>
                <mat-card-content class="salary-block">
                  <div>
                    Выплата на руки:
                    <span class="row link" fxFlex="20" fxFlex.lt-sm="40" (click)="onSalaryInfo(wp)">{{
                      wp.total_onhand | number: '0.2-2'
                    }}</span>
                  </div>
                  <div>
                    В том числе, премия (с учетом удержаний):
                    @if (wp.bounty_details) {
                      <span
                        class="row link"
                        fxFlex="20"
                        fxFlex.lt-sm="40"
                        href=""
                        routerLink="/bounty/{{ period.name }}/{{ wp.workplace }}/{{ wp.position }}"
                        >
                        {{ wp.total_bounty | number: '0.2-2' }}
                      </span>
                    }
                    @if (!wp.bounty_details) {
                      <span class="row" fxFlex="20" fxFlex.lt-sm="40">{{
                        wp.total_bounty | number: '0.2-2'
                      }}</span>
                    }
                  </div>
                </mat-card-content>
              </mat-card>
            }
          </mat-expansion-panel>
        }
      </mat-accordion>
    </div>
  </mat-list>
} @else {
  <div class="view-loader">
    <div class="spinner">
      <div class="double-bounce1 mat-bg-accent"></div>
      <div class="double-bounce2 mat-bg-primary"></div>
    </div>
  </div>
}

