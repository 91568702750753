import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink } from '@angular/router';

@Component({
    selector: 'app-alert-dialog',
    imports: [MatDialogModule, MatIconModule, MatButtonModule, RouterLink],
    templateUrl: './alert-dialog.component.html',
    styleUrl: './alert-dialog.component.scss'
})
export class AlertDialogComponent {
  message: string = 'An unspecified error has occurred';

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private data: {
      message: string;
      buttonText: string;
    },
    private dialogRef: MatDialogRef<AlertDialogComponent>
  ) {
    if (data?.message) this.message = data.message;
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
