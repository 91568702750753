@if (!page) {
  <div class="view-loader">
    <div class="spinner">
      <div class="double-bounce1 mat-bg-accent"></div>
      <div class="double-bounce2 mat-bg-primary"></div>
    </div>
  </div>
}

@if (page) {
  <app-view-doc [html]="page.html"></app-view-doc>
}
