import { Directive, HostBinding, Output, EventEmitter, ChangeDetectorRef, Input } from '@angular/core';
import { fromEvent } from 'rxjs';
import { tap } from 'rxjs/operators';
import { MatSidenav } from '@angular/material/sidenav';

@Directive({
  selector: '[appOpenSidebarOnSwipe]',
  standalone: true,
})

export class OpenSidebarOnSwipeDirective {
  sidebar: MatSidenav | undefined;
  @Input('sideNav') set sideNav(sideNav: MatSidenav){
    this.sidebar = sideNav;
  }

  swipeStarted = false;
  swipeStartX = 0;
  swipeStartY = 0;

  constructor() {
  }
  ngAfterViewInit(){
    // console.log("touching init");
    this.startOnTouch();
  }

  startOnTouch() {
    fromEvent<TouchEvent>(document, 'touchstart').pipe(
      tap((e: TouchEvent) => {
        // console.log("touchstart:",  e)
        if (e.changedTouches.length) {
          // console.log("touchstart:",  e.changedTouches[0].clientX)
          this.swipeStarted = true;
          this.swipeStartX = e.changedTouches[0].clientX;
          this.swipeStartY = e.changedTouches[0].clientY;
        }
      })
    ).subscribe();

    fromEvent<TouchEvent>(document, 'touchend').pipe(
      tap((e: TouchEvent) => {
        // console.log("touchend:",  e.changedTouches[0].clientX)
        if (this.swipeStarted && e.changedTouches.length) {
          const swipeEndX = e.changedTouches[0].clientX;
          const swipeEndY = e.changedTouches[0].clientY;
          const horSwipe = Math.abs(this.swipeStartY - swipeEndY) * 3 < Math.abs(this.swipeStartX - swipeEndX);
          if (horSwipe && this.sidebar) {
            if (!this.sidebar.opened && (this.swipeStartX - swipeEndX < -50)) {
              // console.log("touchend - open sidebar");
              this.sidebar.open();
            } else if (this.sidebar.opened && (this.swipeStartX - swipeEndX > 50)) {
              // console.log("touchend - close sidebar");
              this.sidebar.close();
            }
          }
          this.swipeStarted = false;
        }
      })
    ).subscribe();
  }
}

