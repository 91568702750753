<mat-nav-list>
  <mat-list-item lines="1" mat-button routerLink="/account">
    <div matListItemTitle>
      {{ auth.getProfile()?.debug_login_override ? auth.getProfile()?.debug_login_override : auth.getProfile()?.login }}
    </div>
    {{ auth.getProfile()?.personaldata?.individual_name }}
  </mat-list-item>
  <mat-icon  (click)="toogleTheme()" class="float-theme-button">{{ theme == 'light' ? 'light_mode' : 'dark_mode'}}"</mat-icon>
</mat-nav-list>

