import { Component, OnInit } from '@angular/core';
// import { MatDialog } from '@angular/material/dialog';
import { IProfile } from '../../model';
import { AuthService } from '../../services/auth.service';
// import { ViewAccountComponent } from '../view-account.component/view-account.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';

import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';
import { ThemeService } from 'src/app/services/theme.service';
import { RouterLink } from '@angular/router';

@Component({
    selector: 'app-account-box',
    templateUrl: './account-box.component.html',
    styleUrls: ['./account-box.component.scss'],
    standalone: true,
    imports: [
    MatListModule,
    MatIconModule,
    MatTooltipModule,
    MatButtonModule,
    RouterLink
],
})
export class AccountBoxComponent implements OnInit {
  public profile: IProfile | null = null;
  public tooltip: string = "";
  public theme = "light";

  constructor(public auth: AuthService, /*public dialog: MatDialog, */private themeService: ThemeService) {}

  // openDialog(): void {
  //   const dialogRef = this.dialog.open(ViewAccountComponent, {
  //     // width: '300px',
  //     data: {},
  //     position: {},
  //   });

  //   dialogRef.afterClosed().subscribe(() => {
  //     console.log('The dialog was closed');
  //   });
  // }

  ngOnInit(): void {
    this.tooltip = `Debug mode. Original: ${this.auth.getProfile()?.login}`;
  }

  toogleTheme(): void {
    this.theme = this.theme == 'light' ? 'dark' : 'light';
    this.themeService.changeTheme(this.theme);
  }
}
