import { LiveAnnouncer } from '@angular/cdk/a11y';

import { AfterViewInit, Component, inject, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatSort, MatSortModule, Sort } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';


export interface PeriodicElement {
  name: string;
  service: string;
  days: Array<string>;
  
}
const ELEMENT_DATA: PeriodicElement[] = [
  {name: "Перова И.К.", service: 'FO',  days: ["2", '8','8','8','8']},
  {name: "Сидоров И.К.", service: 'HC', days: ["3", '','8',' ',' ']},
  {name: "Иванова И.К.", service: 'EN', days: [" ", '',' ','8','8']},
  {name: "Федоров-Водкинг И.К.", service: 'EN', days: ["4", '8','8','8','8']},
  {name: "Сидро-педров И.К.", service: 'EN', days: ["4", '8','','8','8']},
  {name: "Загитов С.К.", service: 'FNB', days: ["", '','','3','8']},
  {name: "Ахматов И.Т.", service: 'СП', days: ["s", '2','3','8','8']},
  {name: "Ахматов И.Т.", service: 'FO', days: ["s", '','3','8','8']},
  {name: "Ахматов И.Т.", service: 'FO', days: ["s", '2','3','8','8']},
  {name: "Ахматов И.Т.", service: 'FO', days: ["s", '2','','8','8']},
  {name: "Ахматов И.Т.", service: 'FO', days: ["s", '','3','8','8']},
  {name: "Ахматов И.Т.", service: 'СП', days: ["s", '2','3','8','8']},
  {name: "Иванова И.К.", service: 'EN', days: ["1", '','8','8','8']},
  {name: "Ахматов И.Т.", service: 'HC', days: ["s", '2','3','8','8']},
  {name: "Ахматов И.Т.", service: 'HC', days: ["s", '','3','8','8']},
];

@Component({
  selector: 'app-view-shifts',
  standalone: true,
  imports: [MatTableModule, MatSortModule, MatSelectModule, MatFormFieldModule, FormsModule, MatIconModule],
  templateUrl: './view-shifts.component.html',
  styleUrl: './view-shifts.component.scss'
})
export class ViewShiftsComponent implements AfterViewInit {

  private _liveAnnouncer = inject(LiveAnnouncer);

  displayedColumns: string[] = ['name', 'service'];
  displayedDateCols: string[] = [];
  dataSource = new MatTableDataSource(ELEMENT_DATA);

  @ViewChild(MatSort) sort: MatSort;

  constructor() {
    for (let i = 0; i < 31; i++) {
      const col =(i + 1).toString();
      this.displayedColumns.push(col);
      this.displayedDateCols.push(col);
    }
  }


  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  announceSortChange(sortState: Sort) {
    // This example uses English messages. If your application supports
    // multiple language, you would internationalize these strings.
    // Furthermore, you can customize the message to add additional
    // details about the values being sorted.
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  get_icon(service: string): string {
    if (service === 'FO') {
      return "person";
    } else if (service === 'HC') {
      return "cleaning_services";
    } else if (service === 'FNB') {
      return 'restaurant';
    } else if (service === 'EN') {
      return 'engineering';
    } else {
      return 'question_mark';
    }
  }
}
