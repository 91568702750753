import * as i0 from '@angular/core';
import { Component, ChangeDetectionStrategy, Pipe, InjectionToken, PLATFORM_ID, Injectable, Inject, Optional, EventEmitter, Input, Output, SecurityContext, NgModule } from '@angular/core';
import { Subject, merge, of, timer } from 'rxjs';
import { switchMap, mapTo, distinctUntilChanged, shareReplay, startWith, map, takeUntil, first } from 'rxjs/operators';
import * as i1 from '@angular/common';
import { isPlatformBrowser, CommonModule } from '@angular/common';
import { Renderer, marked } from 'marked';
const _c0 = ["*"];
export { Renderer as MarkedRenderer } from 'marked';
import * as i1$1 from '@angular/common/http';
import * as i4 from '@angular/platform-browser';
const BUTTON_TEXT_COPY = 'Copy';
const BUTTON_TEXT_COPIED = 'Copied';
class ClipboardButtonComponent {
  constructor() {
    this._buttonClick$ = new Subject();
    this.copied$ = this._buttonClick$.pipe(switchMap(() => merge(of(true), timer(3000).pipe(mapTo(false)))), distinctUntilChanged(), shareReplay(1));
    this.copiedText$ = this.copied$.pipe(startWith(false), map(copied => copied ? BUTTON_TEXT_COPIED : BUTTON_TEXT_COPY));
  }
  onCopyToClipboardClick() {
    this._buttonClick$.next();
  }
  static {
    this.ɵfac = function ClipboardButtonComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || ClipboardButtonComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: ClipboardButtonComponent,
      selectors: [["markdown-clipboard"]],
      decls: 4,
      vars: 7,
      consts: [[1, "markdown-clipboard-button", 3, "click"]],
      template: function ClipboardButtonComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "button", 0);
          i0.ɵɵpipe(1, "async");
          i0.ɵɵlistener("click", function ClipboardButtonComponent_Template_button_click_0_listener() {
            return ctx.onCopyToClipboardClick();
          });
          i0.ɵɵtext(2);
          i0.ɵɵpipe(3, "async");
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵclassProp("copied", i0.ɵɵpipeBind1(1, 3, ctx.copied$));
          i0.ɵɵadvance(2);
          i0.ɵɵtextInterpolate(i0.ɵɵpipeBind1(3, 5, ctx.copiedText$));
        }
      },
      dependencies: [i1.AsyncPipe],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ClipboardButtonComponent, [{
    type: Component,
    args: [{
      selector: 'markdown-clipboard',
      template: `
    <button
      class="markdown-clipboard-button"
      [class.copied]="copied$ | async"
      (click)="onCopyToClipboardClick()"
    >{{ copiedText$ | async }}</button>
  `,
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], null, null);
})();
class ClipboardOptions {}
class ClipboardRenderOptions extends ClipboardOptions {}

/* eslint-disable */
class KatexSpecificOptions {}
class LanguagePipe {
  transform(value, language) {
    if (value == null) {
      value = '';
    }
    if (language == null) {
      language = '';
    }
    if (typeof value !== 'string') {
      console.error(`LanguagePipe has been invoked with an invalid value type [${typeof value}]`);
      return value;
    }
    if (typeof language !== 'string') {
      console.error(`LanguagePipe has been invoked with an invalid parameter [${typeof language}]`);
      return value;
    }
    return '```' + language + '\n' + value + '\n```';
  }
  static {
    this.ɵfac = function LanguagePipe_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || LanguagePipe)();
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "language",
      type: LanguagePipe,
      pure: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LanguagePipe, [{
    type: Pipe,
    args: [{
      name: 'language'
    }]
  }], null, null);
})();
var PrismPlugin;
(function (PrismPlugin) {
  PrismPlugin["CommandLine"] = "command-line";
  PrismPlugin["LineHighlight"] = "line-highlight";
  PrismPlugin["LineNumbers"] = "line-numbers";
})(PrismPlugin || (PrismPlugin = {}));
class MarkedOptions {}

/* eslint-disable max-len */
const errorJoyPixelsNotLoaded = '[ngx-markdown] When using the `emoji` attribute you *have to* include Emoji-Toolkit files to `angular.json` or use imports. See README for more information';
const errorKatexNotLoaded = '[ngx-markdown] When using the `katex` attribute you *have to* include KaTeX files to `angular.json` or use imports. See README for more information';
const errorMermaidNotLoaded = '[ngx-markdown] When using the `mermaid` attribute you *have to* include Mermaid files to `angular.json` or use imports. See README for more information';
const errorClipboardNotLoaded = '[ngx-markdown] When using the `clipboard` attribute you *have to* include Clipboard files to `angular.json` or use imports. See README for more information';
const errorClipboardViewContainerRequired = '[ngx-markdown] When using the `clipboard` attribute you *have to* provide the `viewContainerRef` parameter to `MarkdownService.render()` function';
const errorSrcWithoutHttpClient = '[ngx-markdown] When using the `src` attribute you *have to* pass the `HttpClient` as a parameter of the `forRoot` method. See README for more information';
/* eslint-enable max-len */
const SECURITY_CONTEXT = new InjectionToken('SECURITY_CONTEXT');
class ExtendedRenderer extends Renderer {
  constructor() {
    super(...arguments);
    this.ɵNgxMarkdownRendererExtended = false;
  }
}
class MarkdownService {
  get options() {
    return this._options;
  }
  set options(value) {
    this._options = {
      ...this.DEFAULT_MARKED_OPTIONS,
      ...value
    };
  }
  get renderer() {
    return this.options.renderer;
  }
  set renderer(value) {
    this.options.renderer = value;
  }
  constructor(platform, securityContext, http, clipboardOptions, options, sanitizer) {
    this.platform = platform;
    this.securityContext = securityContext;
    this.http = http;
    this.clipboardOptions = clipboardOptions;
    this.sanitizer = sanitizer;
    this.DEFAULT_MARKED_OPTIONS = {
      renderer: new Renderer()
    };
    this.DEFAULT_KATEX_OPTIONS = {
      delimiters: [{
        left: "$$",
        right: "$$",
        display: true
      }, {
        left: "$",
        right: "$",
        display: false
      }, {
        left: "\\(",
        right: "\\)",
        display: false
      }, {
        left: "\\begin{equation}",
        right: "\\end{equation}",
        display: true
      }, {
        left: "\\begin{align}",
        right: "\\end{align}",
        display: true
      }, {
        left: "\\begin{alignat}",
        right: "\\end{alignat}",
        display: true
      }, {
        left: "\\begin{gather}",
        right: "\\end{gather}",
        display: true
      }, {
        left: "\\begin{CD}",
        right: "\\end{CD}",
        display: true
      }, {
        left: "\\[",
        right: "\\]",
        display: true
      }]
    };
    this.DEFAULT_MERMAID_OPTIONS = {
      startOnLoad: false
    };
    this.DEFAULT_CLIPBOARD_OPTIONS = {
      buttonComponent: undefined
    };
    this.DEFAULT_PARSE_OPTIONS = {
      decodeHtml: false,
      inline: false,
      emoji: false,
      mermaid: false,
      markedOptions: this.DEFAULT_MARKED_OPTIONS,
      disableSanitizer: false
    };
    this.DEFAULT_RENDER_OPTIONS = {
      clipboard: false,
      clipboardOptions: undefined,
      katex: false,
      katexOptions: undefined,
      mermaid: false,
      mermaidOptions: undefined
    };
    this._reload$ = new Subject();
    this.reload$ = this._reload$.asObservable();
    this.options = options;
  }
  parse(markdown, parseOptions = this.DEFAULT_PARSE_OPTIONS) {
    const {
      decodeHtml,
      inline,
      emoji,
      mermaid,
      disableSanitizer
    } = parseOptions;
    const markedOptions = {
      ...this.options,
      ...parseOptions.markedOptions
    };
    if (mermaid) {
      this.renderer = this.extendRenderer(markedOptions.renderer || new Renderer());
    }
    const trimmed = this.trimIndentation(markdown);
    const decoded = decodeHtml ? this.decodeHtml(trimmed) : trimmed;
    const emojified = emoji ? this.parseEmoji(decoded) : decoded;
    const marked = this.parseMarked(emojified, markedOptions, inline);
    const sanitized = disableSanitizer ? marked : this.sanitizer.sanitize(this.securityContext, marked);
    return sanitized || '';
  }
  render(element, options = this.DEFAULT_RENDER_OPTIONS, viewContainerRef) {
    const {
      clipboard,
      clipboardOptions,
      katex,
      katexOptions,
      mermaid,
      mermaidOptions
    } = options;
    if (clipboard) {
      this.renderClipboard(element, viewContainerRef, {
        ...this.DEFAULT_CLIPBOARD_OPTIONS,
        ...this.clipboardOptions,
        ...clipboardOptions
      });
    }
    if (katex) {
      this.renderKatex(element, {
        ...this.DEFAULT_KATEX_OPTIONS,
        ...katexOptions
      });
    }
    if (mermaid) {
      this.renderMermaid(element, {
        ...this.DEFAULT_MERMAID_OPTIONS,
        ...mermaidOptions
      });
    }
    this.highlight(element);
  }
  reload() {
    this._reload$.next();
  }
  getSource(src) {
    if (!this.http) {
      throw new Error(errorSrcWithoutHttpClient);
    }
    return this.http.get(src, {
      responseType: 'text'
    }).pipe(map(markdown => this.handleExtension(src, markdown)));
  }
  highlight(element) {
    if (!isPlatformBrowser(this.platform)) {
      return;
    }
    if (typeof Prism === 'undefined' || typeof Prism.highlightAllUnder === 'undefined') {
      return;
    }
    if (!element) {
      element = document;
    }
    const noLanguageElements = element.querySelectorAll('pre code:not([class*="language-"])');
    Array.prototype.forEach.call(noLanguageElements, x => x.classList.add('language-none'));
    Prism.highlightAllUnder(element);
  }
  decodeHtml(html) {
    if (!isPlatformBrowser(this.platform)) {
      return html;
    }
    const textarea = document.createElement('textarea');
    textarea.innerHTML = html;
    return textarea.value;
  }
  extendRenderer(renderer) {
    const extendedRenderer = renderer;
    if (extendedRenderer.ɵNgxMarkdownRendererExtended === true) {
      return renderer;
    }
    // eslint-disable-next-line @typescript-eslint/unbound-method
    const defaultCode = renderer.code;
    renderer.code = function (code, language, isEscaped) {
      return language === 'mermaid' ? `<div class="mermaid">${code}</div>` : defaultCode.call(this, code, language, isEscaped);
    };
    extendedRenderer.ɵNgxMarkdownRendererExtended = true;
    return renderer;
  }
  handleExtension(src, markdown) {
    const urlProtocolIndex = src.lastIndexOf('://');
    const urlWithoutProtocol = urlProtocolIndex > -1 ? src.substring(urlProtocolIndex + 4) : src;
    const lastSlashIndex = urlWithoutProtocol.lastIndexOf('/');
    const lastUrlSegment = lastSlashIndex > -1 ? urlWithoutProtocol.substring(lastSlashIndex + 1).split('?')[0] : '';
    const lastDotIndex = lastUrlSegment.lastIndexOf('.');
    const extension = lastDotIndex > -1 ? lastUrlSegment.substring(lastDotIndex + 1) : '';
    return !!extension && extension !== 'md' ? '```' + extension + '\n' + markdown + '\n```' : markdown;
  }
  parseMarked(html, markedOptions, inline = false) {
    return inline ? marked.parseInline(html, markedOptions) : marked.parse(html, markedOptions);
  }
  parseEmoji(html) {
    if (!isPlatformBrowser(this.platform)) {
      return html;
    }
    if (typeof joypixels === 'undefined' || typeof joypixels.shortnameToUnicode === 'undefined') {
      throw new Error(errorJoyPixelsNotLoaded);
    }
    return joypixels.shortnameToUnicode(html);
  }
  renderKatex(element, options) {
    if (!isPlatformBrowser(this.platform)) {
      return;
    }
    if (typeof katex === 'undefined' || typeof renderMathInElement === 'undefined') {
      throw new Error(errorKatexNotLoaded);
    }
    renderMathInElement(element, options);
  }
  renderClipboard(element, viewContainerRef, options) {
    if (!isPlatformBrowser(this.platform)) {
      return;
    }
    if (typeof ClipboardJS === 'undefined') {
      throw new Error(errorClipboardNotLoaded);
    }
    if (!viewContainerRef) {
      throw new Error(errorClipboardViewContainerRequired);
    }
    const {
      buttonComponent,
      buttonTemplate
    } = options;
    // target every <pre> elements
    const preElements = element.querySelectorAll('pre');
    for (let i = 0; i < preElements.length; i++) {
      const preElement = preElements.item(i);
      // create <pre> wrapper element
      const preWrapperElement = document.createElement('div');
      preWrapperElement.style.position = 'relative';
      preElement.parentNode.insertBefore(preWrapperElement, preElement);
      preWrapperElement.appendChild(preElement);
      // create toolbar element
      const toolbarWrapperElement = document.createElement('div');
      toolbarWrapperElement.style.position = 'absolute';
      toolbarWrapperElement.style.top = '.5em';
      toolbarWrapperElement.style.right = '.5em';
      toolbarWrapperElement.style.opacity = '0';
      toolbarWrapperElement.style.transition = 'opacity 250ms ease-out';
      preWrapperElement.insertAdjacentElement('beforeend', toolbarWrapperElement);
      // register listener to show/hide toolbar
      preElement.onmouseover = () => toolbarWrapperElement.style.opacity = '1';
      preElement.onmouseout = () => toolbarWrapperElement.style.opacity = '0';
      // declare embeddedViewRef holding variable
      let embeddedViewRef;
      // use provided component via input property
      // or provided via ClipboardOptions provider
      if (buttonComponent) {
        const componentRef = viewContainerRef.createComponent(buttonComponent);
        embeddedViewRef = componentRef.hostView;
      }
      // use provided template via input property
      else if (buttonTemplate) {
        embeddedViewRef = viewContainerRef.createEmbeddedView(buttonTemplate);
      }
      // use default component
      else {
        const componentRef = viewContainerRef.createComponent(ClipboardButtonComponent);
        embeddedViewRef = componentRef.hostView;
      }
      // declare clipboard instance variable
      let clipboardInstance;
      // attach clipboard.js to root node
      embeddedViewRef.rootNodes.forEach(node => {
        node.onmouseover = () => toolbarWrapperElement.style.opacity = '1';
        toolbarWrapperElement.appendChild(node);
        clipboardInstance = new ClipboardJS(node, {
          text: () => preElement.innerText
        });
      });
      // destroy clipboard instance when view is destroyed
      embeddedViewRef.onDestroy(() => clipboardInstance.destroy());
    }
  }
  renderMermaid(element, options = this.DEFAULT_MERMAID_OPTIONS) {
    if (!isPlatformBrowser(this.platform)) {
      return;
    }
    if (typeof mermaid === 'undefined' || typeof mermaid.init === 'undefined') {
      throw new Error(errorMermaidNotLoaded);
    }
    const mermaidElements = element.querySelectorAll('.mermaid');
    if (mermaidElements.length === 0) {
      return;
    }
    mermaid.initialize(options);
    mermaid.init(mermaidElements);
  }
  trimIndentation(markdown) {
    if (!markdown) {
      return '';
    }
    let indentStart;
    return markdown.split('\n').map(line => {
      let lineIdentStart = indentStart;
      if (line.length > 0) {
        lineIdentStart = isNaN(lineIdentStart) ? line.search(/\S|$/) : Math.min(line.search(/\S|$/), lineIdentStart);
      }
      if (isNaN(indentStart)) {
        indentStart = lineIdentStart;
      }
      return lineIdentStart ? line.substring(lineIdentStart) : line;
    }).join('\n');
  }
  static {
    this.ɵfac = function MarkdownService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || MarkdownService)(i0.ɵɵinject(PLATFORM_ID), i0.ɵɵinject(SECURITY_CONTEXT), i0.ɵɵinject(i1$1.HttpClient, 8), i0.ɵɵinject(ClipboardOptions, 8), i0.ɵɵinject(MarkedOptions, 8), i0.ɵɵinject(i4.DomSanitizer));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: MarkdownService,
      factory: MarkdownService.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MarkdownService, [{
    type: Injectable
  }], function () {
    return [{
      type: Object,
      decorators: [{
        type: Inject,
        args: [PLATFORM_ID]
      }]
    }, {
      type: i0.SecurityContext,
      decorators: [{
        type: Inject,
        args: [SECURITY_CONTEXT]
      }]
    }, {
      type: i1$1.HttpClient,
      decorators: [{
        type: Optional
      }]
    }, {
      type: ClipboardOptions,
      decorators: [{
        type: Optional
      }]
    }, {
      type: MarkedOptions,
      decorators: [{
        type: Optional
      }]
    }, {
      type: i4.DomSanitizer
    }];
  }, null);
})();

/* eslint-disable @typescript-eslint/no-unused-vars */
class MarkdownComponent {
  get disableSanitizer() {
    return this._disableSanitizer;
  }
  set disableSanitizer(value) {
    this._disableSanitizer = this.coerceBooleanProperty(value);
  }
  get inline() {
    return this._inline;
  }
  set inline(value) {
    this._inline = this.coerceBooleanProperty(value);
  }
  get srcRelativeLink() {
    return this._srcRelativeLink;
  }
  set srcRelativeLink(value) {
    this._srcRelativeLink = this.coerceBooleanProperty(value);
  }
  // Plugin - clipboard
  get clipboard() {
    return this._clipboard;
  }
  set clipboard(value) {
    this._clipboard = this.coerceBooleanProperty(value);
  }
  // Plugin - emoji
  get emoji() {
    return this._emoji;
  }
  set emoji(value) {
    this._emoji = this.coerceBooleanProperty(value);
  }
  // Plugin - katex
  get katex() {
    return this._katex;
  }
  set katex(value) {
    this._katex = this.coerceBooleanProperty(value);
  }
  // Plugin - mermaid
  get mermaid() {
    return this._mermaid;
  }
  set mermaid(value) {
    this._mermaid = this.coerceBooleanProperty(value);
  }
  // Plugin - lineHighlight
  get lineHighlight() {
    return this._lineHighlight;
  }
  set lineHighlight(value) {
    this._lineHighlight = this.coerceBooleanProperty(value);
  }
  // Plugin - lineNumbers
  get lineNumbers() {
    return this._lineNumbers;
  }
  set lineNumbers(value) {
    this._lineNumbers = this.coerceBooleanProperty(value);
  }
  // Plugin - commandLine
  get commandLine() {
    return this._commandLine;
  }
  set commandLine(value) {
    this._commandLine = this.coerceBooleanProperty(value);
  }
  constructor(element, markdownService, viewContainerRef) {
    this.element = element;
    this.markdownService = markdownService;
    this.viewContainerRef = viewContainerRef;
    // Event emitters
    this.error = new EventEmitter();
    this.load = new EventEmitter();
    this.ready = new EventEmitter();
    this._clipboard = false;
    this._commandLine = false;
    this._disableSanitizer = false;
    this._emoji = false;
    this._inline = false;
    this._katex = false;
    this._lineHighlight = false;
    this._lineNumbers = false;
    this._mermaid = false;
    this._srcRelativeLink = false;
    this.destroyed$ = new Subject();
  }
  ngOnChanges() {
    this.loadContent();
  }
  loadContent() {
    if (this.data != null) {
      this.handleData();
      return;
    }
    if (this.src != null) {
      this.handleSrc();
      return;
    }
  }
  ngAfterViewInit() {
    if (!this.data && !this.src) {
      this.handleTransclusion();
    }
    this.markdownService.reload$.pipe(takeUntil(this.destroyed$)).subscribe(() => this.loadContent());
  }
  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
  render(markdown, decodeHtml = false) {
    let markedOptions;
    if (this.src && this.srcRelativeLink) {
      const baseUrl = new URL(this.src, location.origin).pathname;
      markedOptions = {
        baseUrl
      };
    }
    const parsedOptions = {
      decodeHtml,
      inline: this.inline,
      emoji: this.emoji,
      mermaid: this.mermaid,
      markedOptions,
      disableSanitizer: this.disableSanitizer
    };
    const renderOptions = {
      clipboard: this.clipboard,
      clipboardOptions: {
        buttonComponent: this.clipboardButtonComponent,
        buttonTemplate: this.clipboardButtonTemplate
      },
      katex: this.katex,
      katexOptions: this.katexOptions,
      mermaid: this.mermaid,
      mermaidOptions: this.mermaidOptions
    };
    const parsed = this.markdownService.parse(markdown, parsedOptions);
    this.element.nativeElement.innerHTML = parsed;
    this.handlePlugins();
    this.markdownService.render(this.element.nativeElement, renderOptions, this.viewContainerRef);
    this.ready.emit();
  }
  coerceBooleanProperty(value) {
    return value != null && `${String(value)}` !== 'false';
  }
  handleData() {
    this.render(this.data);
  }
  handleSrc() {
    this.markdownService.getSource(this.src).subscribe({
      next: markdown => {
        this.render(markdown);
        this.load.emit(markdown);
      },
      error: error => this.error.emit(error)
    });
  }
  handleTransclusion() {
    this.render(this.element.nativeElement.innerHTML, true);
  }
  handlePlugins() {
    if (this.commandLine) {
      this.setPluginClass(this.element.nativeElement, PrismPlugin.CommandLine);
      this.setPluginOptions(this.element.nativeElement, {
        dataFilterOutput: this.filterOutput,
        dataHost: this.host,
        dataPrompt: this.prompt,
        dataOutput: this.output,
        dataUser: this.user
      });
    }
    if (this.lineHighlight) {
      this.setPluginOptions(this.element.nativeElement, {
        dataLine: this.line,
        dataLineOffset: this.lineOffset
      });
    }
    if (this.lineNumbers) {
      this.setPluginClass(this.element.nativeElement, PrismPlugin.LineNumbers);
      this.setPluginOptions(this.element.nativeElement, {
        dataStart: this.start
      });
    }
  }
  setPluginClass(element, plugin) {
    const preElements = element.querySelectorAll('pre');
    for (let i = 0; i < preElements.length; i++) {
      const classes = plugin instanceof Array ? plugin : [plugin];
      preElements.item(i).classList.add(...classes);
    }
  }
  setPluginOptions(element, options) {
    const preElements = element.querySelectorAll('pre');
    for (let i = 0; i < preElements.length; i++) {
      Object.keys(options).forEach(option => {
        const attributeValue = options[option];
        if (attributeValue) {
          const attributeName = this.toLispCase(option);
          preElements.item(i).setAttribute(attributeName, attributeValue.toString());
        }
      });
    }
  }
  toLispCase(value) {
    const upperChars = value.match(/([A-Z])/g);
    if (!upperChars) {
      return value;
    }
    let str = value.toString();
    for (let i = 0, n = upperChars.length; i < n; i++) {
      str = str.replace(new RegExp(upperChars[i]), '-' + upperChars[i].toLowerCase());
    }
    if (str.slice(0, 1) === '-') {
      str = str.slice(1);
    }
    return str;
  }
  static {
    this.ɵfac = function MarkdownComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || MarkdownComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(MarkdownService), i0.ɵɵdirectiveInject(i0.ViewContainerRef));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: MarkdownComponent,
      selectors: [["markdown"], ["", "markdown", ""]],
      inputs: {
        data: "data",
        src: "src",
        disableSanitizer: "disableSanitizer",
        inline: "inline",
        srcRelativeLink: "srcRelativeLink",
        clipboard: "clipboard",
        clipboardButtonComponent: "clipboardButtonComponent",
        clipboardButtonTemplate: "clipboardButtonTemplate",
        emoji: "emoji",
        katex: "katex",
        katexOptions: "katexOptions",
        mermaid: "mermaid",
        mermaidOptions: "mermaidOptions",
        lineHighlight: "lineHighlight",
        line: "line",
        lineOffset: "lineOffset",
        lineNumbers: "lineNumbers",
        start: "start",
        commandLine: "commandLine",
        filterOutput: "filterOutput",
        host: "host",
        prompt: "prompt",
        output: "output",
        user: "user"
      },
      outputs: {
        error: "error",
        load: "load",
        ready: "ready"
      },
      features: [i0.ɵɵNgOnChangesFeature],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function MarkdownComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MarkdownComponent, [{
    type: Component,
    args: [{
      // eslint-disable-next-line @angular-eslint/component-selector
      selector: 'markdown, [markdown]',
      template: '<ng-content></ng-content>'
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: MarkdownService
    }, {
      type: i0.ViewContainerRef
    }];
  }, {
    data: [{
      type: Input
    }],
    src: [{
      type: Input
    }],
    disableSanitizer: [{
      type: Input
    }],
    inline: [{
      type: Input
    }],
    srcRelativeLink: [{
      type: Input
    }],
    clipboard: [{
      type: Input
    }],
    clipboardButtonComponent: [{
      type: Input
    }],
    clipboardButtonTemplate: [{
      type: Input
    }],
    emoji: [{
      type: Input
    }],
    katex: [{
      type: Input
    }],
    katexOptions: [{
      type: Input
    }],
    mermaid: [{
      type: Input
    }],
    mermaidOptions: [{
      type: Input
    }],
    lineHighlight: [{
      type: Input
    }],
    line: [{
      type: Input
    }],
    lineOffset: [{
      type: Input
    }],
    lineNumbers: [{
      type: Input
    }],
    start: [{
      type: Input
    }],
    commandLine: [{
      type: Input
    }],
    filterOutput: [{
      type: Input
    }],
    host: [{
      type: Input
    }],
    prompt: [{
      type: Input
    }],
    output: [{
      type: Input
    }],
    user: [{
      type: Input
    }],
    error: [{
      type: Output
    }],
    load: [{
      type: Output
    }],
    ready: [{
      type: Output
    }]
  });
})();
class MarkdownPipe {
  constructor(domSanitizer, elementRef, markdownService, viewContainerRef, zone) {
    this.domSanitizer = domSanitizer;
    this.elementRef = elementRef;
    this.markdownService = markdownService;
    this.viewContainerRef = viewContainerRef;
    this.zone = zone;
  }
  transform(value, options) {
    if (value == null) {
      return '';
    }
    if (typeof value !== 'string') {
      console.error(`MarkdownPipe has been invoked with an invalid value type [${typeof value}]`);
      return value;
    }
    const markdown = this.markdownService.parse(value, options);
    this.zone.onStable.pipe(first()).subscribe(() => this.markdownService.render(this.elementRef.nativeElement, options, this.viewContainerRef));
    return this.domSanitizer.bypassSecurityTrustHtml(markdown);
  }
  static {
    this.ɵfac = function MarkdownPipe_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || MarkdownPipe)(i0.ɵɵdirectiveInject(i4.DomSanitizer, 16), i0.ɵɵdirectiveInject(i0.ElementRef, 16), i0.ɵɵdirectiveInject(MarkdownService, 16), i0.ɵɵdirectiveInject(i0.ViewContainerRef, 16), i0.ɵɵdirectiveInject(i0.NgZone, 16));
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "markdown",
      type: MarkdownPipe,
      pure: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MarkdownPipe, [{
    type: Pipe,
    args: [{
      name: 'markdown'
    }]
  }], function () {
    return [{
      type: i4.DomSanitizer
    }, {
      type: i0.ElementRef
    }, {
      type: MarkdownService
    }, {
      type: i0.ViewContainerRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
const sharedDeclarations = [ClipboardButtonComponent, LanguagePipe, MarkdownComponent, MarkdownPipe];
class MarkdownModule {
  static forRoot(markdownModuleConfig) {
    return {
      ngModule: MarkdownModule,
      providers: [MarkdownService, markdownModuleConfig && markdownModuleConfig.loader || [], markdownModuleConfig && markdownModuleConfig.clipboardOptions || [], markdownModuleConfig && markdownModuleConfig.markedOptions || [], {
        provide: SECURITY_CONTEXT,
        useValue: markdownModuleConfig && markdownModuleConfig.sanitize != null ? markdownModuleConfig.sanitize : SecurityContext.HTML
      }]
    };
  }
  static forChild() {
    return {
      ngModule: MarkdownModule
    };
  }
  static {
    this.ɵfac = function MarkdownModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || MarkdownModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: MarkdownModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MarkdownModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      exports: sharedDeclarations,
      declarations: sharedDeclarations
    }]
  }], null, null);
})();

/* eslint-disable */
var MermaidAPI;
(function (MermaidAPI) {
  let SecurityLevel;
  (function (SecurityLevel) {
    /**
     * (default) tags in text are encoded, click functionality is disabled
     */
    SecurityLevel["Strict"] = "strict";
    /**
     * tags in text are allowed, click functionality is enabled
     */
    SecurityLevel["Loose"] = "loose";
    /**
     * html tags in text are allowed, (only script element is removed), click functionality is enabled
     */
    SecurityLevel["Antiscript"] = "antiscript";
    /**
     * with this security level all rendering takes place in a sandboxed iframe.
     * This prevent any javascript running in the context.
     * This may hinder interactive functionality of the diagram like scripts,
     * popups in sequence diagram or links to other tabs/targets etc.
     */
    SecurityLevel["Sandbox"] = "sandbox";
  })(SecurityLevel = MermaidAPI.SecurityLevel || (MermaidAPI.SecurityLevel = {}));
  let Theme;
  (function (Theme) {
    /**
     * Designed to modified, as the name implies it is supposed to be used as the base for making custom themes.
     */
    Theme["Base"] = "base";
    /**
     * A theme full of light greens that is easy on the eyes.
     */
    Theme["Forest"] = "forest";
    /**
     * A theme that would go well with other dark colored elements.
     */
    Theme["Dark"] = "dark";
    /**
     *  The default theme for all diagrams.
     */
    Theme["Default"] = "default";
    /**
     * The theme to be used for black and white printing
     */
    Theme["Neutral"] = "neutral";
  })(Theme = MermaidAPI.Theme || (MermaidAPI.Theme = {}));
  let LogLevel;
  (function (LogLevel) {
    LogLevel[LogLevel["Debug"] = 1] = "Debug";
    LogLevel[LogLevel["Info"] = 2] = "Info";
    LogLevel[LogLevel["Warn"] = 3] = "Warn";
    LogLevel[LogLevel["Error"] = 4] = "Error";
    LogLevel[LogLevel["Fatal"] = 5] = "Fatal";
  })(LogLevel = MermaidAPI.LogLevel || (MermaidAPI.LogLevel = {}));
})(MermaidAPI || (MermaidAPI = {}));

/**
 * Generated bundle index. Do not edit.
 */

export { ClipboardButtonComponent, ClipboardOptions, ClipboardRenderOptions, ExtendedRenderer, KatexSpecificOptions, LanguagePipe, MarkdownComponent, MarkdownModule, MarkdownPipe, MarkdownService, MarkedOptions, MermaidAPI, PrismPlugin, SECURITY_CONTEXT, errorClipboardNotLoaded, errorClipboardViewContainerRequired, errorJoyPixelsNotLoaded, errorKatexNotLoaded, errorMermaidNotLoaded, errorSrcWithoutHttpClient };
