<app-auth-layout appTitle="Вход">
  <form fxLayout="column" fxLayoutGap="2em" [formGroup]="loginForm" >

    <!-- (cdkAutofill)="loginForm.updateValueAndValidity(); loggg()" -->

    <!-- login  -->
    <mat-form-field appearance="fill" floatLabel="always">
      <mat-label>Телефон</mat-label>
      <span matPrefix>+7 &nbsp;</span>
      <input
        matInput
        type="tel"
        maxlength="13"
        appPhoneMask
        formControlName="phoneNumber"
        autocomplete="mobile username"
        />
      <mat-hint>Только Россия (+7)</mat-hint>
      @if (phoneNumber.hasError('required')) {
        <mat-error>
          Введите номер телефона
        </mat-error>
      }
      @if (phoneNumber.errors?.['minlength']) {
        <mat-error>
          Номер телефона должен содержать не менее 10 цифр.
        </mat-error>
      }
    </mat-form-field>

    <!-- password  -->
    @if (['first_login', 'ready_to_login'].includes(userStatus$ | async)) {
      <mat-form-field appearance="fill" floatLabel="always">
        <mat-label>Пароль</mat-label>
        <input
          [type]="passwordVisibility ? 'text' : 'password'"
          matInput
          formControlName="password"
          autocomplete="current-password"
          />
        <button matSuffix mat-icon-button (click)="togglePasswordVisibility()">
          <mat-icon>{{ passwordVisibility ? 'visibility_off' : 'visibility' }}</mat-icon>
        </button>
        <mat-hint>Минимум 6 символов.</mat-hint>
        @if (password.hasError('required')) {
          <mat-error>
            Введите пароль
          </mat-error>
        }
        @if (password.hasError('minlength')) {
          <mat-error>
            Пароль должен содержать не менее 6 символов
          </mat-error>
        }
      </mat-form-field>
    }

    <!-- confirm password  -->
    @if (['first_login'].includes(userStatus$ | async)) {
      <mat-form-field appearance="fill" floatLabel="always">
        <mat-label>Подтвердите пароль</mat-label>
        <input
          [type]="confirmPasswordVisibility ? 'text' : 'password'"
          matInput
          formControlName="confirmPassword"
          autocomplete="current-password"
          />
        <button matSuffix mat-icon-button (click)="toggleConfirmPasswordVisibility()">
          <mat-icon>{{ confirmPasswordVisibility ? 'visibility_off' : 'visibility' }}</mat-icon>
        </button>
        @if (confirmPassword.hasError('required')) {
          <mat-error>
            Введите пароль
          </mat-error>
        }
        @if (confirmPassword.hasError('minlength')) {
          <mat-error>
            Пароль должен содержать не менее 6 символов
          </mat-error>
        }
        @if (confirmPassword.hasError('mismatch')) {
          <mat-error>
            Пароли не совпадают
          </mat-error>
        }
      </mat-form-field>
    }

    <button mat-raised-button color="primary" (click)="login()" [disabled]="loginForm.invalid">Войти</button>

    @if ((userStatus$ | async) == 'ready_to_login') {
      <div>
        <a class="auth-link" routerLink="/reset_password">Забыли пароль?</a>
        <!-- <a class="auth-link" routerLink="/register">Регистрация</a> -->
        <!-- <a class="auth-link" routerLink="">Не могу войти</a> -->
      </div>
    }
    <p>{{ version }}</p>

    <!-- <p> Статус: {{ userStatus$ | async }}</p>
    <p  *ngIf="(userStatus$ | async) == 'first_login'"> Задайте пароль к первому логину </p>
    <p  *ngIf="(userStatus$ | async) == 'ready_to_login'"> Готов к логину </p> -->

  </form>
</app-auth-layout>
