@if (html || url) {
  <div fxFill>
    @if (html) {
      <iframe [srcdoc]="html"></iframe>
    }
    @if (url) {
      <iframe [src]="url"></iframe>
    }
  </div>
}
