import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeResourceUrl } from '@angular/platform-browser';
import { FlexModule } from '@angular/flex-layout/flex';


@Component({
    selector: 'app-view-doc',
    templateUrl: './view-doc.component.html',
    styleUrls: ['./view-doc.component.scss'],
    standalone: true,
    imports: [FlexModule],
})
export class ViewDocComponent implements OnInit {
  @Input() html: SafeHtml | string = "";
  @Input() url: SafeResourceUrl | string = "";

  constructor(private sanitize: DomSanitizer) {}

  ngOnInit(): void {
    if (this.html) {
      if (typeof this.html === 'string') {
        this.html = this.sanitize.bypassSecurityTrustHtml(this.html);
      }
    } else if (this.url) {
      if (typeof this.url === 'string') {
        this.url = this.sanitize.bypassSecurityTrustResourceUrl(this.url);
      }
    }
  }
}
