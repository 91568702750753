@if (magazines$ | async; as magazines) {
  <mat-form-field class="selector" appearance="fill">
    <mat-label>{{ 'Выбор периода' }}</mat-label>
    <mat-select fill [(ngModel)]="selectedValue" name="period" (selectionChange)="updateMagazinePdfUrl()">
      @for (option of magazines; track option) {
        <mat-option [value]="option.value" ngDefaultControl>
          {{ option.value }}
        </mat-option>
      }
    </mat-select>
  </mat-form-field>
} @else {
  <div class="view-loader">
    <div class="spinner">
      <div class="double-bounce1 mat-bg-accent"></div>
      <div class="double-bounce2 mat-bg-primary"></div>
    </div>
  </div>
}

<pdf-viewer [src]="pdfUrl" [render-text]="true" fit-to-page="true" class="pdf-viewer"></pdf-viewer>

