import { Component, OnInit } from '@angular/core';
import { IVacation } from '../../model';
import { ApiService } from '../../services/api.service';
import { AuthService } from '../../services/auth.service';
import { AsyncPipe } from '@angular/common';
import { Observable } from 'rxjs';
import { MatListModule } from '@angular/material/list';

@Component({
    selector: 'app-view-graphic-vacation',
    templateUrl: './view-graphic-vacation.component.html',
    styleUrls: ['./view-graphic-vacation.component.scss'],
    standalone: true,
    imports: [AsyncPipe, MatListModule],
})
export class ViewGraphicVacationComponent {
  vacations$: Observable<IVacation[]>;
  constructor(private apiService: ApiService, public auth: AuthService) {
    this.vacations$ = this.apiService.getPersonalVacation()
  }
}
