import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ISalaryRecord, IWorkplacePosition } from 'src/app/model';
import { egretAnimations } from '../../animations/egret-animations';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatButtonModule } from '@angular/material/button';
import { DecimalPipe } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
import { MatCardModule } from '@angular/material/card';

interface MatDialogData {
  title: string;
  company: string;
  position: string;
  salaryPeriod: IWorkplacePosition;
}

interface PaymentGroup {
  name: string;
  payments: ISalaryRecord[];
}
@Component({
    selector: 'app-view-salary-popup',
    templateUrl: './view-salary-popup.component.html',
    styleUrls: ['./view-salary-popup.component.scss'],
    animations: egretAnimations,
    standalone: true,
    imports: [
    MatCardModule,
    MatDividerModule,
    MatButtonModule,
    MatProgressBarModule,
    DecimalPipe
],
})
export class ViewSalaryPopupComponent implements OnInit {
  paymentGroups: PaymentGroup[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: MatDialogData,
    public dialogRef: MatDialogRef<ViewSalaryPopupComponent>,
  ) {
    for (const payment of data.salaryPeriod.payments) {
      let group: PaymentGroup | unknown = this.paymentGroups.find((pp) => pp.name === payment.subaccount);
      if (!group) {
        group = {
          name: payment.subaccount,
          payments: [],
        };
        this.paymentGroups.push(group as PaymentGroup);
      }

      (group as PaymentGroup).payments.push(payment);
    }

    console.log('SalaryPopup', this.paymentGroups);
  }

  ngOnInit(): void {
    console.log('SalaryPopup', this.data);
  }

  submit(): void {
    this.dialogRef.close();
  }
}
