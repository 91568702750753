import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NotificationService } from './../services/notification.service';
import { ErrorCodesService } from './error-codes.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(public notificationService: NotificationService, private errCodesService: ErrorCodesService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.url.indexOf('heartbeat') > 0) {
      return next.handle(request); // skip error console writing for heartbeats
    }

    return next.handle(request).pipe(
      catchError((err) => {
        if (err instanceof HttpErrorResponse) {
          let message = typeof err.error === 'string' ? err.error : err.name;
          message = this.errCodesService.decodeErrorMessage(message);
          console.error('ErrorInterceptor', err, `${err.status} - ${err.message} - ${err.error}`);
          this.notificationService.notify(`${message}\n(Код ошибки: ${err.status})`);
        } else {
          console.error('ErrorInterceptor', err.toString());
          this.notificationService.notify(err.toString());
        }
        return throwError(err);
      })
    );
  }
}
