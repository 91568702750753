import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { Router } from '@angular/router';
// import { version } from 'package.json';
import { IPersonalData, IProfile } from 'src/app/model';
import { ApiService } from 'src/app/services/api.service';
import { AuthService2 } from 'src/app/services/auth.service';
import { MatButtonModule } from '@angular/material/button';
import { DatePipe } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
import { MatLineModule } from '@angular/material/core';
import { MatListModule } from '@angular/material/list';
import { FlexModule } from '@angular/flex-layout/flex';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { PersonalData, UserProfile } from 'src/app/modules/api';

@Component({
    selector: 'app-account-box-dialog',
    templateUrl: './view-account.component.html',
    styleUrls: ['./view-account.component.scss'],
    imports: [
        FlexModule,
        MatListModule,
        MatLineModule,
        MatDividerModule,
        MatButtonModule,
        MatDialogModule,
        MatCardModule,
        MatExpansionModule,
        DatePipe
    ]
})
export class ViewAccountComponent implements OnInit {
  public version: string = "unknown"; //version;
  public personal_data: PersonalData | null = null;
  public profile: UserProfile | null = null;

  constructor(
    public auth: AuthService2,
    // public dialogRef: MatDialogRef<ViewAccountComponent>,
    public apiService: ApiService,
    public router: Router
  ) {
    this.profile = this.auth.getProfile()
    this.personal_data = this.auth.getProfile().personaldata;
  }

  ngOnInit(): void {
  }

}
