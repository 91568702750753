import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ErrorCodesService {
  codes = new Map<string, string>([
    ['Error.Forbidden', 'Доступ запрещен'],
    ['Error.MissingOrBadRequestParameter', 'Ошибочный или отсутствующий параметр'],
    ['Error.WrongPassword', 'Неправильный пароль'],
    ['Error.UserAlreadyExist', 'Пользователь уже зарегистрирован'],
    ['Error.UserNotFound', 'Пользователь не найден'],
    ['Error.UserNotRegistered', 'Пользователь не зарегистрирован в системе'],
    ['Error.AlreadyActivated', 'Аккаунт уже зарегистрирован'],
    ['Error.NoLoginOrPassword', 'Отсутствуют логин или пароль'],
    ['Error.FailedToSendSMS', 'Ошибка отправки SMS'],
    ['Error.MissingSMSCode', 'Отсутствует SMS код'],
    ['Error.WrongSMSCode', 'Неправильный SMS код'],
    ['Error.MaxActivationAttemptsReached', 'Превышено максимальное количество попыток'],
    ['Error.NoPersonalData', 'Отсутствует информация о пользователе'],
    ['Error.NoUserLoginInZup', `Номер телефона не найден в 1С ЗУП.
    Просьба направить электронное письмо по адресу
    dev@station-hotels.ru с указанием ФИО заявителя,
    должности и актуального номера мобильного телефона.`],
  ]);

  public decodeErrorMessage(message: string): string {
    if (this.codes.has(message)) {
      return this.codes.get(message) as string;
    }
    return message;
  }
}
