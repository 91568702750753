<app-auth-layout appTitle="Сброс пароля">
  <form fxLayout="column" fxLayoutGap="2em" [formGroup]="form">
    <mat-form-field appearance="fill" floatLabel="always">
      <mat-label>Номер телефона</mat-label>
      <span matPrefix>+7 &nbsp;</span>
      <input
        matInput
        type="tel"
        maxlength="13"
        appPhoneMask
        formControlName="phoneNumber"
        autocomplete="mobile username"
        />
      <mat-hint>Только Россия (+7)</mat-hint>
      @if (phoneNumber.hasError('required')) {
        <mat-error>
          Введите номер телефона
        </mat-error>
      }
      @if (phoneNumber.hasError('minlength')) {
        <mat-error>
          Номер телефона должен содержать не менее 10 цифр
        </mat-error>
      }
    </mat-form-field>

    <mat-form-field appearance="fill" floatLabel="always">
      <mat-label>Код для сброса пароля</mat-label>
      <input matInput type="text" maxlength="6" formControlName="code" />
      @if (code.hasError('minlength')) {
        <mat-error>
          Код должен содержать не менее 4 символов
        </mat-error>
      }
      <button class="code-button" matSuffix (click)="requestResetPasswordCode()" color="primary" mat-raised-button [disabled]="code.value.length==6 || phoneNumber.errors">
        Запрос кода
      </button>
      @if (code.hasError('wrongCode')) {
        <mat-error>
          Неправильный код
        </mat-error>
      }
      <mat-hint>Высылается через SMS cлужбой поддежки по запросу</mat-hint>
    </mat-form-field>

    <div class="button-row">
      <button color="primary" mat-raised-button [disabled]="form.invalid" (click)="resetPassword()">
        Сбосить пароль
      </button>
      <span></span>
      <button color="primary" mat-raised-button routerLink="/login">
        Назад
      </button>
    </div>
    <p>Если SMS c кодом не приходит более суток, обратитесь в <a  href="tel:+79114800693">поддержку</a></p>
  </form>
</app-auth-layout>


<!--
<ng-container *ngIf="this.code_sent">
  <mat-form-field appearance="fill" floatLabel="always">
    <mat-label>СМС код</mat-label>
    <input matInput type="code" formControlName="code" />
    <mat-error>
      {{ getCodeErrorMessage() }}
    </mat-error>
  </mat-form-field>
  <mat-form-field appearance="fill" floatLabel="always">
    <mat-label>Новый пароль</mat-label>
    <input
      [type]="passwordVisibility ? 'text' : 'password'"
      matInput
      type="password"
      formControlName="password"
      autocomplete="new-password"
      />
    <button matSuffix mat-icon-button (click)="togglePasswordVisibility()">
      <mat-icon>{{ passwordVisibility ? 'visibility_off' : 'visibility' }}</mat-icon>
    </button>
    <mat-hint>Минимум 6 символов.</mat-hint>
    <mat-error>
      {{ getPasswordErrorMessage() }}
    </mat-error>
  </mat-form-field>
</ng-container>
<ng-container *ngIf="!code_sent; else showSubmitNewPassword">
  <button (click)="submitUsername()" color="primary" mat-raised-button [disabled]="form.get('phoneNumber').invalid">
    Отправить подтверждение
  </button>
</ng-container>
<ng-template #showSubmitNewPassword>
  <button (click)="submitPassword()" color="primary" mat-raised-button [disabled]="form.invalid">
    Сменить пароль
  </button>
</ng-template> -->
